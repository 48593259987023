import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

class BaseService {

    collection = '';

    getRegisters(){
        return firebase.firestore().collection(this.collection)
    }

    getRegister(id){
        return firebase.firestore()
        .collection(this.collection)
        .doc(id)
        .get();
    }

    getById(id){
        return firebase.firestore()
        .collection(this.collection)
        .doc(id)
        .get();
    }

    getByField(field, value){
        return firebase.firestore()
        .collection(this.collection)
        .where(field, '==', value)
        .get();
    }

    addRegister(data, callback){
        firebase.firestore()
        .collection(this.collection)
        .add(data)
        .then(docRef => {
            callback(docRef.id);
        });
    }

    setRegister(id, data, callback){
        firebase.firestore()
        .collection(this.collection)
        .doc(id)
        .set(data)
        .then(docRef => {
            callback(docRef);
        });
    }

    updateRegister(id,data, callback){
        firebase.firestore()
        .collection(this.collection)
        .doc(id)
        .update(data)
        .then(callback);
    }

    deleteRegister(id, callback){
        firebase.firestore()
        .collection(this.collection)
        .doc(id)
        .delete()
        .then(callback);
    }
}

export default BaseService;