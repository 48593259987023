import React, { useState } from 'react'
import { login02, warning_icon, success_icon, error_icon, loginlogo } from '../../imagepath';
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";

import { functions } from '../../../utils/functions';
import { alertsApp } from '../../../utils/alertsApp';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const ChangePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [passwordVisible, setPasswordVisible] = useState({ pass: true, confirm: true });
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isBlockSave, setIsBlockSave] = useState(false);
  const [isValidPass, setIsValidPass] = useState(false);
  
  const [keysValidationsPass, setKeysValidationsPass] = useState(['upperLower', 'numbers', 'spetialChars', 'lengthChars']);
  const [validationsPass, setValidationsPass] = useState({
    upperLower: {
      text: 'La contraseña debe contener letras mayúsculas y minúsculas',
      image: warning_icon,
      color: '#ff9e55'
    },
    numbers: {
      text: 'La contraseña debe contener al menos un número',
      image: warning_icon,
      color: '#ff9e55'
    },
    spetialChars: {
      text: 'La contraseña debe contener al menos uno de los siguientes caracteres especial @, -, $, #, _, |',
      image: warning_icon,
      color: '#ff9e55'
    },
    lengthChars: {
      text: 'La contraseña debe contener mínimo 10 caracteres',
      image: warning_icon,
      color: '#ff9e55'
    },
  })

  const onChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'password') {
      validatePass(value);
      setPassword(value);
    }
  };

  const validatePass = (value) => {
    const lettersUpper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lettersLower = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const spetialChars = "@-$#_|";
    let countValid = 0;
    if (value) {
      if (functions.validCharactersIn(value, lettersUpper) && functions.validCharactersIn(value, lettersLower)) {
        setValidation('upperLower', 'success');
      } else {
        setValidation('upperLower', 'error');
        countValid++;
      }

      if (functions.validCharactersIn(value, numbers)) {
        setValidation('numbers', 'success');
      } else {
        setValidation('numbers', 'error');
        countValid++;
      }

      if (functions.validCharactersIn(value, spetialChars)) {
        setValidation('spetialChars', 'success');
      } else {
        setValidation('spetialChars', 'error');
        countValid++;
      }

      if (value.length >= 10) {
        setValidation('lengthChars', 'success');
      } else {
        setValidation('lengthChars', 'error');
        countValid++;
      }
    } else {
      keysValidationsPass.forEach(key => {
        setValidation(key, 'warning');
      })
      countValid++;
    }

    if (countValid == 0) {
      setIsValidPass(true);
    } else {
      setIsValidPass(false);
    }
  }

  const setValidation = (section, type) => {
    let validationsInput = { ...validationsPass };
    if (type === 'success') {
      validationsInput[section].color = '#01ecae';
      validationsInput[section].image = success_icon;
    }
    if (type === 'warning') {
      validationsInput[section].color = '#ff9e55';
      validationsInput[section].image = warning_icon;
    }
    if (type === 'error') {
      validationsInput[section].color = '#ff5352';
      validationsInput[section].image = error_icon;
    }
    setValidationsPass(validationsInput);
  }

  const togglePasswordVisibility = (origin) => () => {
    let visible = { ...passwordVisible };
    if (origin == 'P') {
      visible.pass = !visible.pass
    } else {
      visible.confirm = !visible.confirm
    }
    setPasswordVisible(visible);
  };

  const savePass = () => {
    setIsBlockSave(true);
    let valid = validFields();
    if(valid.valid){
      changePass();
    }else{
      alertsApp.errorAlert(valid.message);
      setIsBlockSave(false);
    }
  }

  const changePass = () => {
    let parametros = new URLSearchParams(location.search)
    console.error(parametros.get('oobCode'));
    console.error(password);
    firebase.auth().confirmPasswordReset(parametros.get('oobCode'), password)
    .then(() => {
        alertsApp.successAlert('Contraseña registrada correctamente');
        setIsBlockSave(false);
        navigate('/');
    })
    .catch(error => {
      console.error(error);
      const errorCode = error.code;
      const errorMessage = error.message;
      if(errorCode === 'auth/expired-action-code'){
        alertsApp.errorAlert('El tiempo para el cambio de clave ha caducado, vuelva a solicitar el cambio de clave a administración.')
        setIsBlockSave(false);
      }
      if(errorCode === 'auth/invalid-action-code'){
        alertsApp.errorAlert('El código es inválido; vuelva a solicitar el cambio de clave a administración.')
        setIsBlockSave(false);
      }
      
    })
  }

  const validFields = () => {
    let result = {valid: true, message: ''};
    if(password == ''){
      result.valid = false;
      result.message = 'La contraseña se encuentra vacia';
    }else{
      if(!isValidPass){
        result.valid = false;
        result.message = 'La contraseña es incorrecta, debe cumplir con todos los requerimientos';
      }else{
        if(confirmPassword != password){
          result.valid = false;
          result.message = 'Confirmación de contraseña incorrecta';
        }
      }
    }
    return result;
  }

  return (
    <>
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img
                    className="img-fluid"
                    src={login02}
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
            {/* /Login logo */}
            {/* Login Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="/admin-dashboard">
                          <img src={loginlogo} alt="#" />
                        </Link>
                      </div>
                      <h2>Ingresar contraseña</h2>
                      {/* Form */}
                      <form action="#">
                        <div className="form-group">
                          <label className="focus-label">Contraseña</label>
                          <input
                            type={passwordVisible.pass ? 'password' : ''}
                            name="password"
                            className="form-control floating"
                            value={password}
                            onChange={onChange}
                          />
                          <span
                            className="toggle-password"
                            onClick={togglePasswordVisibility('P')}
                          >
                            {passwordVisible.pass ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                          </span>
                        </div>
                        <div className="form-group local-forms">
                          <label className="focus-label">Confirmar Contraseña</label>
                          <input
                            type={passwordVisible.confirm ? 'password' : ''}
                            name="confirmPassword"
                            className="form-control floating"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <span
                            className="toggle-password"
                            onClick={togglePasswordVisibility('C')}
                          >
                            {passwordVisible.confirm ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                          </span>
                        </div>
                        <div className="form-group login-btn">
                          <button className="btn btn-primary btn-block" type="button" onClick={savePass} disabled={isBlockSave}>
                            {isBlockSave &&
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            }
                            {isBlockSave ? ' Procesando...' : 'Guardar'}
                          </button>
                        </div>
                      </form>
                      <div className="row">
                        {keysValidationsPass.map((itemKey, indexK) => (
                          <div className="box-message-password col-md-12" key={'valid' + indexK}>
                            <img src={validationsPass[itemKey].image} width="13" style={{ marginRight: 8 }} />
                            <span style={{ color: validationsPass[itemKey].color, lineHeight: '13px' }}>
                              {validationsPass[itemKey].text}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Login Content */}
          </div>
        </div>
      </div>

    </>
  )
}

export default ChangePassword;
