import React, { useState, useEffect } from "react";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import { morning_img_01 } from "../../imagepath";

import Sidebar from "../../Sidebar";
import Header from "../../Header";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';


const Admin_Dashboard = () => {
  const [user, setUser] = useState({displayName: ''});
  const [textDay, setTextDay] = useState('');

  useEffect(() => {
    setUser(firebase.auth().currentUser);
    const date = new Date();
    let hour = date.getHours();
    if(hour >= 5 && hour <= 12){
      setTextDay('Buenos días');
    }else{
      if(hour > 12 && hour <= 19){
        setTextDay('Buenos tardes');
      }else{
        setTextDay('Buenos noches');
      }  
    }
  }, [])

  return (
    <>
      <Header />
      <Sidebar activeClassName="admin-dashboard" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Dashboard </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Admin Dashboard</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="good-morning-blk">
              <div className="row">
                <div className="col-md-6">
                  <div className="morning-user">
                    <h2>
                      {textDay}, <span>{user.displayName}</span>
                    </h2>
                    <p>Que tengas un excelente día en el trabajo</p>
                  </div>
                </div>
                <div className="col-md-6 position-blk">
                  <div className="morning-img">
                    <img src={morning_img_01}
                      alt="#" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Admin_Dashboard;
