import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import BaseService from './base.service';

class MedicinesResidentsService extends BaseService {

    medicines_resident = ''
    constructor(){
        super();
        this.collection = 'medicines_residents';
    }

    getMedicinesResident(idResident){
        let query = firebase.firestore().collection(this.collection)
        query = query.where('id_resident', '==', idResident);
        return query.get();
    }

    setMedicines(data, callback) {

        let batch = firebase.firestore().batch();
        data.forEach((item) => {
            let resultadosCollection = firebase.firestore()
                .collection(this.collection).doc(item.id)
            batch.set(resultadosCollection, item);
        })
        batch.commit().then(() => {
            callback()
        });
    }
}

export const medicinesResidentsService = new MedicinesResidentsService();