class Functions {

  generateDateStr(date, separator, withHour) {
    let day = date.getDate();
    day = this.formatTime(day);
    
    let month = date.getMonth() + 1;
    month = this.formatTime(month);

    let hour = '';
    if(withHour){
      hour = ' '+this.generateHourStr(date);  
    }
    
    let dateShow = date.getFullYear() + separator + month + separator + day + hour;
    return dateShow;
  }

  generateHourStr(date) {
    let hour = date.getHours();
    hour = this.formatTime(hour);

    let minutes = date.getMinutes();
    minutes = this.formatTime(minutes);

    let seconds = date.getSeconds();
    seconds = this.formatTime(seconds);
    
    return hour+':'+minutes+':'+seconds;
  }

  formatTime(time) {
    let timeStr = '';
    if (time < 10) {
      timeStr = '0' + time;
    } else {
      timeStr = time;
    }
    return timeStr;
  }

  validIdentification(numero) {
    let result = {
      numero: numero.toString(),
      valid: false,
      codigo_provincia: null,
      tipo_de_cedula: null,
      mensaje: ''
    }
    let digito_verificador, i, modulo, multiplicadores, p, producto, productos, provincias, residuo, suma, tercer_digito, verificador, _i, _j, _k, _l, _len, _len1, _ref, _ref1;
    if ((_ref = result.numero.length) !== 10 && _ref !== 13) {
      result.valid = false;
      result.mensaje = "Longitud incorrecta.";
    }
    provincias = 22;
    result.codigo_provincia = parseInt(result.numero.substr(0, 2), 10);
    if (result.codigo_provincia < 1 || result.codigo_provincia > provincias) {
      result.valid = false;
      result.mensaje = "Código de provincia incorrecto.";
    }
    tercer_digito = parseInt(result.numero[2], 10);
    if (tercer_digito === 7 || tercer_digito === 8) {
      result.mensaje = "Tercer dígito es inválido.";
    }
    if (tercer_digito === 9) {
      result.tipo_de_cedula = "Sociedad privada o extranjera";
    } else if (tercer_digito === 6) {
      result.tipo_de_cedula = "Sociedad pública";
    } else if (tercer_digito < 6) {
      result.tipo_de_cedula = "Persona natural";
    }
    productos = [];
    if (tercer_digito < 6) {
      modulo = 10;
      verificador = parseInt(result.numero.substr(9, 1), 10);
      p = 2;
      _ref1 = result.numero.substr(0, 9);
      for (_i = 0, _len = _ref1.length; _i < _len; _i++) {
        i = _ref1[_i];
        producto = parseInt(i, 10) * p;
        if (producto >= 10) {
          producto -= 9;
        }
        productos.push(producto);
        if (p === 2) {
          p = 1;
        } else {
          p = 2;
        }
      }
    }
    if (tercer_digito === 6) {
      verificador = parseInt(result.numero.substr(8, 1), 10);
      modulo = 11;
      multiplicadores = [3, 2, 7, 6, 5, 4, 3, 2];
      for (i = _j = 0; _j <= 7; i = ++_j) {
        productos[i] = parseInt(result.numero[i], 10) * multiplicadores[i];
      }
      productos[8] = 0;
    }
    if (tercer_digito === 9) {
      verificador = parseInt(result.numero.substr(9, 1), 10);
      modulo = 11;
      multiplicadores = [4, 3, 2, 7, 6, 5, 4, 3, 2];
      for (i = _k = 0; _k <= 8; i = ++_k) {
        productos[i] = parseInt(result.numero[i], 10) * multiplicadores[i];
      }
    }
    suma = 0;
    for (_l = 0, _len1 = productos.length; _l < _len1; _l++) {
      i = productos[_l];
      suma += i;
    }
    residuo = suma % modulo;
    digito_verificador = residuo === 0 ? 0 : modulo - residuo;
    if (tercer_digito === 6) {
      if (result.numero.substr(9, 4) !== "0001") {
        result.mensaje = "RUC de empresa del sector público debe terminar en 0001";
      }
      result.valid = digito_verificador === verificador;
    }
    if (tercer_digito === 9) {
      if (result.numero.substr(10, 3) !== "001") {
        result.mensaje = "RUC de entidad privada debe terminar en 001";
      }
      result.valid = digito_verificador === verificador;
    }
    if (tercer_digito < 6) {
      if (result.numero.length > 10 && result.numero.substr(10, 3) !== "001") {
        result.mensaje = "RUC de persona natural debe terminar en 001";
      }
      result.valid = digito_verificador === verificador;
    }
    return result;
  }

  validPhone(phone) {
    let result = true;
    let numbersPhone = phone.substr(0, 2);
    if (numbersPhone !== '09' || phone.length !== 10) {
      result = false;
    }
    return result;
  }

  validNumber(value) {
    let positionChart = value.length - 1;
    let chartsAnt = value.substr(0, positionChart);
    let result = value;
    if(value.substr(positionChart, 1) != '.'){
      if (isNaN(value.substr(positionChart, 1))) {
        result = chartsAnt;
      }
    }
    
    return result;
  }

  validEmail(email) {
    let result = true;
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789_.-';
    const arroba = new RegExp('@');
    const point = new RegExp('.');
    if (arroba.test(email)) {
      let arrayEmail = email.split('@');
      let nameEmail = arrayEmail[0];
      let domainEmail = arrayEmail[1];

      result = this.validCharactersOut(nameEmail, characters);
      if(nameEmail.indexOf('..') != -1){
        result = false;
      }

      if(point.test(domainEmail)){
        if(domainEmail.indexOf('..') != -1){
          result = false;
        }else{
          let arrayDomain = domainEmail.split('.');
          if (arrayDomain[1].length > 4) {
            result = false;
          }
        }
      }else{
        result = false;
      }
    }else{
      result = false;
    }
    return result;
  }

  validCharactersIn(value, characters) {
    for (let i = 0; i < value.length; i++) {
      if (characters.indexOf(value.charAt(i), 0) != -1) {
        return true;
      }
    }
    return false;
  }

  validCharactersOut(value, characters) {
    for (let i = 0; i < value.length; i++) {
      if (characters.indexOf(value.charAt(i), 0) == -1) {
        return false;
      }
    }
    return true;
  }
  
  randomPass(){
    let charters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdfghijklmnopqrstuvwxyz";
    let max = charters.length - 1;
    let pass = "";
    for (let i=0; i < 10; i++) { 
        pass += charters.substr(Math.floor(Math.random() * max), 1);
    }
    return pass;
  }

  replaceAll(text, busca, reemplaza) {
    while (text.toString().indexOf(busca) !== -1)
        text = text.toString().replace(busca, reemplaza);
    return text;
  }

  formatTA(value){
    let arrayValue = value.split('/');
    let itemT = parseInt(this.replaceAll(arrayValue[0],'_', ''));
    let itemA = parseInt(this.replaceAll(arrayValue[1],'_', ''));
    return itemT+'/'+itemA;
  }

  stringToTimestamp(dateStr){
    let date = new Date(dateStr);
    return date.getTime();
  }

  timestampToDate(timestamp){
    let date = new Date(timestamp);
    return date;
  }

  timestampToString(timestamp, format){
    let date = new Date(timestamp);
    if(format.indexOf('YYYY') != -1){
      format = format.replace('YYYY', date.getFullYear());
    }
    if(format.indexOf('mm') != -1){
      let month = date.getMonth() + 1;
      format = format.replace('mm', this.formatTime(month));
    }
    if(format.indexOf('dd') != -1){
      let day = date.getDate();
      format = format.replace('dd', this.formatTime(day));
    }
    if(format.indexOf('h') != -1){
      let hour = date.getHours();
      format = format.replace('h', this.formatTime(hour));
    }
    if(format.indexOf('i') != -1){
      let minutes = date.getMinutes();
      format = format.replace('i', this.formatTime(minutes));
    }
    if(format.indexOf('s') != -1){
      let seconds = date.getSeconds();
      format = format.replace('s', this.formatTime(seconds));
    }
    
    return format;
  }
}

export const functions = new Functions();