import React from "react";
// eslint-disable-next-line no-unused-vars

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Patient_Dashboard from "./components/Dashboard/Patient_Dashboard/Patient_Dashboard";
import Profile from "./components/pages/login/Profile";
import EditProfile from "./components/pages/login/EditProfile";
import Signout from "./components/pages/login/Signout";

import VitalSigns from "./components/vital_signs/VitalSigns";

import Chat from "./components/Chat/Chat";
//Accounts
const AppLogged = () => {
  // eslint-disable-next-line no-unused-vars
  // const config = "/react/template"
  return (
    <>
      {/* <BrowserRouter basename="/react/template"> */}
      <BrowserRouter basename="">
        <Routes>
          {/* Dashboard */}
          <Route path="/" element={<Patient_Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/logout" element={<Signout />} />
          {/* VitalSigns */}
          <Route path="/vitals-signs" element={<VitalSigns />} />
          {/* Chat */}
          <Route path="/chat" element={<Chat />} />
        </Routes>
      </BrowserRouter>
      <div className="sidebar-overlay"></div>
    </>
  );
};

export default AppLogged;
