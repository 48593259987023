import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import BaseService from './base.service';

class ResidentsService extends BaseService {

    resident = ''
    constructor(){
        super();
        this.collection = 'residents';
    }

    getResident(){
        return this.resident;
    }

    setResident(resident){
        this.resident = resident;
    }

    async getResidentAsync(id){
        let result = await firebase.firestore()
        .collection(this.collection)
        .doc(id).get();
        return result.data();
    }
    
    getResidentsUser(idUser){
        let query = firebase.firestore().collection(this.collection)
        query = query.where('id_representative', '==', idUser);
        query = query.where('status', '==', '1');
        return query
    }

    getResidentsAdmin(){
        let query = firebase.firestore().collection(this.collection)
        query = query.where('status', '==', '1');
        return query
    }
}

export const residentsService = new ResidentsService();