import React from 'react';

import AppLogged from "./applogged";
import AppAdmin from './appadmin';
import AppAssistant from './appassistant';
import AppGuest from "./appguest";
import Preloader from './components/Preloader';

import { rolesService } from './services/roles.service';
import { userService } from './services/user.service';
import { alertsApp } from './utils/alertsApp';

import firebaseConfig from './utils/Firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseApp = firebase.initializeApp(firebaseConfig);

export default class App extends React.Component {

  constructor(){
    super();
    this.state = {
      loader: false,
      isLogged: false,
      isAdmin: false,
      isAssistant: false,
      withNotification: false
    }
    this.countSuperAdmin = 0;
  }

  componentDidMount(){
    firebaseApp.auth().onAuthStateChanged((user) => {
      if(user !== null){
        if(user.email !== null){
          this.validateDataUser();
        }
      }else{
        this.setState({loader: true, isLogged: false, isAdmin: false, isAssistant: false });
      }
    })
  }

  validateDataUser(){
    let userDocument = userService.getUser(firebase.auth().currentUser.uid);
    userDocument.then( async(user) => {
      if(user.data().status === '1'){
        if(user.data().rol){
          let dataUser = user.data();
          // this.activeNotification(dataUser);
          this.getRolUser(dataUser);
        }
      }else{
        firebaseApp.auth().signOut().then(() => {
          alertsApp.errorAlert('Su usuario ha sido inactivado, solicite acceso al administrador');
          this.setState({loader: true, isLogged: false, isAdmin: false, isAssistant: false });
          // window.location.reload();
        })
      }
    })
  }

  async getRolUser(dataUser){
    let dataRol = await rolesService.getRolAsync(dataUser.rol);
    if(dataRol.ident_rol == '1'){
      this.setState({loader: true, isLogged: false, isAdmin: true, isAssistant: false});
    }
    if(dataRol.ident_rol == '2'){
      this.setState({loader: true, isLogged: false, isAdmin: false, isAssistant: true});
    }
    if(dataRol.ident_rol == '3'){
      this.setState({loader: true, isLogged: true, isAdmin: false, isAssistant: false});
    }
    dataUser.ident_rol = dataRol.ident_rol
    dataUser.name_rol = dataRol.rol
    userService.setActiveUser(dataUser);
    // if(this.state.withNotification){
    //   // window.location.reload();
    //   this.listenMessage();
    // }
  }

  

  render(){
    const {loader, isLogged, isAdmin, isAssistant } = this.state;
    if(loader){
      if(isLogged){
        return (<AppLogged/>);
      }
      if(isAdmin){
        return (<AppAdmin/>);
      }
      if(isAssistant){
        return (<AppAssistant/>);
      }
      return (<AppGuest/>);
    }else{
      return (<Preloader/>);
    }
  }
}