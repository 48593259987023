/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import { Tooltip } from "@mui/material";
import { plusicon, report_icon } from "../imagepath";
import { imagesend } from "../imagepath";

import { alertsApp } from "../../utils/alertsApp";

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const Attachments = ({ origin, context }) => {
    const { user, setUser } = useContext(context)
    const [ selectAttach, setSelectAttach] = useState('');

    const onChange = (event, index) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let data = { ...user };
        data.attachments[index][name] = value;
        setUser(data);
    };

    const handleImageChange = (event, index) => {
        let _URL = window.URL || window.webkitURL;
        let data = { ...user };
        let file = event.target.files[0];
        data.attachments[index].name = 'nuevo';
        data.attachments[index].file = file;
        data.attachments[index].path = _URL.createObjectURL(file);
        data.attachments[index].type = file.type;
        setUser(data);
    }

    const addAttach = () => {
        let attach = {
            detail: '',
            name: '',
            path: '',
            create: '',
            update: '',
            file: '',
            type: '',
        }
        let data = { ...user };
        data.attachments.push(attach);
        setUser(data);
    }

    const showIconFile = (item) => {
        if (item.path) {
            let arrayType = item.type.split('/');
            if (arrayType[0] === 'image') {
                return (<img src={item.path} height={40} alt={'Imagen de ' + item.detail} />)
            } else {
                return (<img src={report_icon} height={40} alt={'Archivo de ' + item.detail} />)
            }
        } else {
            return (<></>)
        }
    }

    const showFile = (item) => () => {
        window.open(item.path, '_blank');
    }

    const selectRow = (item) => () => {
        setSelectAttach(item);
    }

    const deleteAttach = () => {
        let data = { ...user };
        let index = data.attachments.indexOf(selectAttach);
        data.attachments.splice(index, 1);
        if(origin === 'edit'){
            deleteStorage(selectAttach.name, data);
        }else{
            setSelectAttach('');
            setUser(data);
            alertsApp.successAlert('Adjunto eliminado correctamente');
        }
    }

    const deleteStorage = (nameImage, data) => {
        firebase.storage().ref(nameImage).delete().then(res => {
            setSelectAttach('');
            setUser(data);
            alertsApp.successAlert('Adjunto eliminado correctamente');
        })
    }

    useEffect(() => {
        if(!user.attachments){
            user.attachments = [];
        }
        // setExitDocumentResident(false);
        // if(resident.document){
        //     setExitDocumentResident(true);
        // }
    }, [])

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <div className="add-group">
                    <Tooltip title="Agregar" placement="right">
                        <Link
                            to="#"
                            className="btn btn-primary add-pluss ms-2"
                            onClick={addAttach}
                        >

                            <img src={plusicon} alt="#" />
                        </Link>
                    </Tooltip>
                </div>
            </div>
            {user.attachments && user.attachments.map((item, indexA) => (
                <div className="row" key={'attach' + indexA}>
                    <div className="col-md-4">
                        <div className="form-group local-forms">
                            <label className="focus-label">Detalle <span className="login-danger">*</span></label>
                            <input
                                type="text"
                                name="detail"
                                className="form-control floating"
                                value={item.detail}
                                onChange={(event) => onChange(event, indexA)}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor={"attach-" + indexA}>
                            <input
                                type="file"
                                name={"attach-" + indexA} id={"attach-" + indexA} hidden accept=".pdf, image/*"
                                capture="environment" onChange={(event) => handleImageChange(event, indexA)}
                            />
                            <div className='btn btn-primary' style={{ padding: 10 }}>
                                {item.name === '' ? 'Agregar adjunto' : 'Editar adjunto'}
                            </div>
                        </label>
                    </div>
                    <div className="col-md-2" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ cursor: 'pointer' }} onClick={showFile(item)}>
                            {showIconFile(item)}
                        </div>
                    </div>
                    <div className="col-md-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div style={{color: 'red'}}>
                            <Tooltip title="Eliminar" placement="right">
                                <Link className="dropdown-item" to="#" data-bs-toggle="modal"
                                    data-bs-target="#delete_attach"
                                    onClick={selectRow(item)}
                                >
                                    <i className="fa fa-trash-alt m-r-5"></i>
                                </Link>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            ))}
            <div id="delete_attach" className="modal fade delete-modal" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <img src={imagesend} alt="#" width={50} height={46} />
                            <h3>Esta seguro de eliminar el registro ?</h3>
                            <div className="m-t-20">
                                {" "}
                                <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                                    Cancelar
                                </Link>
                                <button type="button" 
                                    className="btn btn-danger" 
                                    data-bs-dismiss="modal" 
                                    onClick={deleteAttach}
                                >Eliminar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Attachments;
