
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";

import { functions } from '../../utils/functions';
import { alertsApp } from '../../utils/alertsApp';

import { userService } from "../../services/user.service";

const GeneralInformation = ({ origin, context }) => {
    const { user, setUser } = useContext(context);

    const [isEdit, setIsEdit] = useState(false);

    const onChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let data = { ...user };
        if (name === 'document' || name === 'phone') {
            data[name] = functions.validNumber(value);
        } else {
            if (name === 'email') {
                data[name] = value.toLowerCase();
            } else {
                data[name] = value;
            }
        }
        setUser(data);
    };

    const onBlur = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let data = { ...user };
        if (value) {
            if (name === 'email' || name === 'mail') {
                let validEmail = functions.validEmail(value);
                if (!validEmail) {
                    alertsApp.errorAlert('Error: La dirección de correo es incorrecta');
                    data[name] = '';
                    setUser(data);
                } else {
                    getUserByEmail(value, data);
                }
            }

            if (name === 'phone') {
                let validPhone = functions.validPhone(value);
                if (!validPhone) {
                    alertsApp.errorAlert('Número telefónico incorrecto');
                    data[name] = '';
                    setUser(data);
                }
            }

            if (name == 'document') {
                getUserByDocument(value, data);
            }
        }
    }

    const getUserByDocument = (document, data) => {
        userService.getUserByDocument(document).then(snapshot => {
            if (snapshot.docs.length > 0) {
                alertsApp.errorAlert('El número de documento ya se encuentra registrado con otro usuario');
                data.document = '';
                setUser(data);
            }
        })
    }

    const getUserByEmail = (email, data) => {
        userService.getUserByEmail(email).then(snapshot => {
            if (snapshot.docs.length > 0) {
                alertsApp.errorAlert('El email ya se encuentra registrado con otro usuario');
                data.email = '';
                setUser(data);
            }
        })
    }

    useEffect(() => {
        if(origin === 'edit'){
            setIsEdit(true);
        }
      }, [])

    return (
        <div>
            <div className="row">
                <div className="col-md-4">
                    <div className="form-group local-forms">
                        <label className="focus-label">N° documento <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="document"
                            className="form-control floating"
                            maxLength={10}
                            value={user.document}
                            onChange={onChange}
                            onBlur={onBlur}
                            disabled={isEdit}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group local-forms">
                        <label className="focus-label">Primer nombre <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="name"
                            className="form-control floating"
                            value={user.name}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group local-forms">
                        <label className="focus-label">Primer apellido <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="lastname"
                            className="form-control floating"
                            value={user.lastname}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group local-forms">
                        <label className="focus-label">Email <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="email"
                            className="form-control floating"
                            value={user.email}
                            onChange={onChange}
                            onBlur={onBlur}
                            disabled={isEdit}
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group local-forms">
                        <label className="focus-label">N° celular <span className="login-danger">*</span></label>
                        <input
                            type="text"
                            name="phone"
                            className="form-control floating"
                            maxLength={10}
                            value={user.phone}
                            onChange={onChange}
                            onBlur={onBlur}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GeneralInformation;
