import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import BaseService from './base.service';

class RolesService extends BaseService {

    constructor(){
        super();
        this.collection = 'roles';
    }

    async getRolAsync(rolId){
        let rol = await firebase.firestore()
        .collection(this.collection)
        .doc(rolId).get();
        return rol.data();
    }

    async getRolesAsync(){
        let snapshot = await firebase.firestore()
        .collection(this.collection)
        .get();

        let roles = [];
        snapshot.forEach(item => {
            let rol = item.data();
            rol.id = item.id;
            roles.push(rol);
        })

        return roles;
    }

    getRolByName(rolName){
        return firebase.firestore()
        .collection(this.collection)
        .where('rol', '==', rolName)
        .get();
    }

    getRolByIdent(ident_rol){
        return firebase.firestore()
        .collection(this.collection)
        .where('ident_rol', '==', ident_rol)
        .get();
    }
}

export const rolesService = new RolesService();