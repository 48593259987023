/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, createContext } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Header from "../Header";
import Sidebar from "../Sidebar";

import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

import { functions } from '../../utils/functions';
import { alertsApp } from '../../utils/alertsApp';
import { userService } from "../../services/user.service";
import { rolesService } from "../../services/roles.service";
import GeneralInformation from "./GeneralInformation";
import Attachments from "./Attachments";

import firebaseConfig from '../../utils/Firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
const secondaryApp = firebase.initializeApp(firebaseConfig, 'Secondary');

const AssistantContext = createContext(null);

const NewAssistants = () => {
  const [key, setKey] = useState('general_info');
  const [user, setUser] = useState({
    document: '',
    name: '',
    lastname: '',
    email: '',
    phone: '',
    attachments: [],
    status: '',
    create_date: '',
    update_date: ''
  });
  const [isBlockSave, setIsBlockSave] = useState(false);
  const [rolAssistant, setRolAssistant] = useState('');

  const onSelectTab = (key) => {
    setKey(key)
  }

  const cancelSave = () => {
    setIsBlockSave(false);
    setUser({
      document: '',
      name: '',
      lastname: '',
      email: '',
      phone: '',
      status: '',
      attachments: [],
      create_date: '',
      update_date: ''
    })
  }

  const save = () => {
    setIsBlockSave(true);
    user.pass = functions.randomPass();
    user.create_date = Date.now();
    user.update_date = Date.now();
    user.status = '1';
    user.rol = rolAssistant.id;
    user.ident_rol = parseInt(rolAssistant.ident_rol);
    if (!user.name || !user.lastname || !user.document || !user.phone || !user.email) {
      setIsBlockSave(false);
      alertsApp.errorAlert('Los campos del formulario son obligatorios');
    } else {
      sendEmail(user);
    }
  }

  const sendEmail = (user) => {
    // fetch('http://localhost/email_geriatrico/server/api/email/new_assistant', {
    fetch('https://ancianatoenquito.com/php/emailapp/server/api/email/new_assistant', {
      method: 'POST',
      body: JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
      .then(res => res.json())
      .then((result) => {
        if(result['status'] == 1){
          createUser(user);
        }else{
          alertsApp.successAlert('El correo en incorrecto o no admite el mensaje, vuelva a intentar o utilice otro correo')
        }
      })
      .catch(console.error)
  }

  const createUser = (data) => {
    secondaryApp.auth().createUserWithEmailAndPassword(data.email, data.pass)
      .then((res) => {
        let uid = secondaryApp.auth().currentUser.uid;
        updateAuthUser(res, uid, data);
      })
      .catch((err) => {
        console.error(err);
        alertsApp.errorAlert('No se pudo crear el usuario intentelo mas tarde');
      })
  }

  const updateAuthUser = (res, uid, data) => {
    res.user.updateProfile({
      displayName: data.name + ' ' + data.lastname,
    }).then(() => {
      secondaryApp.auth().signOut();
      insert(uid, data);
    }, (error) => {
      console.error(error);
    });
  }

  const insert = (id, data) => {
    let attachments = [...data.attachments]
    delete data.attachments;
    userService.setRegister(id, data, () => {
      if(attachments.length > 0){
        saveImage(attachments, id, 0);
      }else{
        setIsBlockSave(false);
        cancelSave();
        alertsApp.successAlert('Registrado con exito');
      }
    });
  }

  const saveImage = (attachments, id_user, index) => {
    let arrayType = attachments[index].type.split('/');
    let lastIndexAttach = attachments.length - 1;
    let indexImage = index + 1;
    let nameImage = 'attachments/' + id_user + '-' + indexImage + '.'+arrayType[1];
    firebase.storage().ref(nameImage).put(attachments[index].file)
    .then((snapshot) => {
      firebase.storage().ref(nameImage).getDownloadURL()
      .then((url) => {
        if(url){
          attachments[index].name = nameImage;
          attachments[index].file = '';
          attachments[index].path = url;
          attachments[index].create = Date.now();
          attachments[index].update = Date.now();
          
          if (index == lastIndexAttach) {
            updateAttachmentsUser(id_user, attachments)
          } else {
            let nextIndex = index + 1
            saveImage(attachments, id_user, nextIndex);
          }
        }
      })
    });
  }

  const updateAttachmentsUser = (id, attachments) => {
    let data = { attachments: attachments }
    userService.updateRegister(id, data, () => {
      setIsBlockSave(false);
      cancelSave();
      alertsApp.successAlert('Registrado con exito');
    })
  }
  
  const getAssistantRol = () => {
    rolesService.getRolByIdent('2').then(snapshot => {
      let rol = ''
      snapshot.forEach(doc => {
        rol = {
          id: doc.id,
          ident_rol: doc.data().ident_rol
        };
      })
      setRolAssistant(rol);
    })
  }

  useEffect(() => {
    getAssistantRol();
  }, [])

  return (
    <AssistantContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      <Header />
      <Sidebar id="menu-item1" id1="menu-items1" activeClassName="add-assistant" />
      <>
        <div>
          <div className="page-wrapper">
            <div className="content">
              {/* Page Header */}
              <div className="page-header">
                <div className="row">
                  <div className="col-sm-12">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#">Auxiliares</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <i className="feather-chevron-right">
                          <FeatherIcon icon="chevron-right" />
                        </i>
                      </li>
                      <li className="breadcrumb-item active">Nuevo auxiliar</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              <div className="row">
                <div className="col-sm-12">
                  <div className="card-box">
                    <h3 className="card-title">Nuevo auxiliar</h3>
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={(k) => onSelectTab(k)}
                      className="mb-3"
                    >
                      <Tab eventKey="general_info" title="Datos personales">
                        {key === 'general_info' &&
                          <GeneralInformation origin="add" context={AssistantContext} />
                        }
                      </Tab>
                      <Tab eventKey="attachments" title="Adjuntos">
                        {key === 'attachments' &&
                          <Attachments origin="add" context={AssistantContext} />
                        }
                      </Tab>
                    </Tabs>
                    <div className="row" style={{ justifyContent: 'end' }}>
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="doctor-submit">
                          <button
                            type="button"
                            className="btn btn-light submit-list-form me-2"
                            onClick={cancelSave}
                          >
                            Cancelar
                          </button>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="doctor-submit">
                          <button
                            type="button"
                            className="btn btn-primary submit-list-form me-2"
                            onClick={save}
                            disabled={isBlockSave}
                          >
                            {isBlockSave &&
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            }
                            {isBlockSave ? ' Procesando...' : 'Registrar'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </AssistantContext.Provider>
  );
};

export default NewAssistants;
