/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, createContext, useContext } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Header from "../Header";
import Sidebar from "../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from 'react-router-dom';

import { functions } from '../../utils/functions';
import { alertsApp } from '../../utils/alertsApp';
import { userService } from "../../services/user.service";
import { residentsService } from "../../services/resident.service";
import { rolesService } from "../../services/roles.service";
import { medicinesResidentsService } from "../../services/medicines_resident.service";

import PersonalInformation from "./PersonalInformation";
import MedicinesResident from "./MedicinesResident";
import Attachments from "./Attachments";

import firebaseConfig from '../../utils/Firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
const secondaryApp = firebase.initializeApp(firebaseConfig, 'Secondary');

const ResidentContext = createContext(null);

const AddPatients = () => {
  const [key, setKey] = useState('home');
  const [user, setUser] = useState({
    document: '',
    name: '',
    lastname: '',
    email: '',
    phone: '',
    phone1: '',
    phone2: '',
    status: '',
    create_date: '',
    update_date: ''
  });
  const [resident, setResident] = useState({
    document: '',
    name: '',
    lastname: '',
    blood_type: '',
    gender: '',
    security_type: '',
    country: '',
    birthday: '',
    room_number: '0',
    bed_number: '0',
    monthly_pension: '',
    payday: '0',
    id_representative: '',
    representative: '',
    method_pay: '',
    attachments: [],
    status: '',
    diabetic: false,
    create_date: '',
    update_date: '',
    user_create: '',
    user_update: ''
  });
  const [medicines, setMedicines] = useState([]);

  const [isBlockSave, setIsBlockSave] = useState(false);
  const [rolRepresentative, setRolRepresentative] = useState('');

  const onSelectTab = (key) => {
    setKey(key)
  }

  const cancelSave = () => {
    setIsBlockSave(false);
    setUser({
      document: '',
      name: '',
      lastname: '',
      email: '',
      phone: '',
      phone1: '',
      phone2: '',
      status: '',
      create_date: '',
      update_date: ''
    })
    setResident({
      document: '',
      name: '',
      lastname: '',
      blood_type: '',
      gender: '',
      security_type: '',
      country: '',
      birthday: '',
      room_number: '0',
      bed_number: '0',
      monthly_pension: '0',
      payday: '0',
      id_representative: '',
      representative: '',
      method_pay: '',
      attachments: [],
      status: '',
      diabetic: false,
      create_date: '',
      update_date: '',
      user_create: '',
      user_update: ''
    })
    setMedicines([]);
  }

  const save = () => {
    setIsBlockSave(true);
    user.pass = functions.randomPass();
    user.create_date = Date.now();
    user.update_date = Date.now();
    user.user_create = firebase.auth().currentUser.uid;
    user.user_update = firebase.auth().currentUser.uid;
    user.status = '1';
    user.rol = rolRepresentative.id
    user.ident_rol = parseInt(rolRepresentative.ident_rol)
    if (validFields()) {
      getUserByDocument(user.document, user);
    } else {
      setIsBlockSave(false);
      alertsApp.errorAlert('Existen campos obligatorios vacios');
    }
  }

  const validFields = () => {
    let result = true;
    if (!resident.document || !resident.name || !resident.lastname || !resident.blood_type || !resident.gender ||
      !resident.security_type || !resident.country || !resident.birthday || !resident.room_number ||
      !resident.bed_number || !resident.monthly_pension || !resident.payday || !resident.method_pay ) {
      result = false;
    }

    if (!user.name || !user.lastname || !user.document || !user.phone || !user.email) {
      result = false;
    }
    return result;
  }

  const getUserByDocument = (document, data) => {
    userService.getUserByDocument(document).then(snapshot => {
      if (snapshot.docs.length > 0) {
        let userRegister = '';
        snapshot.forEach(item => {
          userRegister = item.data();
          userRegister.id = item.id
        }) 
        updateUser(userRegister.id, data);
      }else{
        sendEmail(data);
      }
    })
  }

  const sendEmail = (user) => {
    // fetch('http://localhost/email_geriatrico/server/api/email/new_assistant', {
    fetch('https://ancianatoenquito.com/php/emailapp/server/api/email/new_resident', {
      method: 'POST',
      body: JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
      .then(res => res.json())
      .then((result) => {
        if(result['status'] == 1){
          createUser(user);
        }else{
          alertsApp.successAlert('El correo en incorrecto o no admite el mensaje, vuelva a intentar o utilice otro correo')
        }
      })
      .catch(console.error)
  }

  const createUser = (data) => {
    secondaryApp.auth().createUserWithEmailAndPassword(data.email, data.pass)
      .then((res) => {
        let uid = secondaryApp.auth().currentUser.uid;
        updateAuthUser(res, uid, data);
      })
      .catch((err) => {
        console.error(err);
        alertsApp.errorAlert('No se pudo crear el usuario intentelo mas tarde');
      })
  }

  const updateAuthUser = (res, uid, data) => {
    res.user.updateProfile({
      displayName: data.name + ' ' + data.lastname,
    }).then(() => {
      secondaryApp.auth().signOut();
      insertUser(uid, data);
    }, (error) => {
      console.error(error);
    });
  }

  const insertUser = (id, data) => {
    userService.setRegister(id, data, () => {
      insertResident(id);
    });
  }

  const updateUser = (id, data) => {
    let dataUpdate = {
      phone: data.phone?data.phone:'',
      phone1: data.phone1?data.phone1:'',
      phone2: data.phone2?data.phone2:'',
      update_date: Date.now(),
      user_update: firebase.auth().currentUser.uid
    }
    userService.updateRegister(id, dataUpdate, () => {
      insertResident(id);
    });
  }

  const insertResident = (id_representative) => {
    resident.id_representative = id_representative;
    resident.representative = user.name + ' ' + user.lastname;
    resident.status = '1';
    resident.create_date = Date.now();
    resident.update_date = Date.now();
    resident.user_create = firebase.auth().currentUser.uid;
    resident.user_update = firebase.auth().currentUser.uid;
    let attachments = [...resident.attachments]
    delete resident.attachments;
    residentsService.addRegister(resident, (idResident) => {
      saveMedicines(idResident, attachments);
    });
  }

  const saveMedicines = (idResident, attachments) => {
    let data = [...medicines];
    data.forEach((item) => {
      item.status = '1';
      item.id_resident = idResident;
      item.create_date = Date.now();
      item.update_date = Date.now();
      item.user_create = firebase.auth().currentUser.uid;
      item.user_update = firebase.auth().currentUser.uid;
    })
    medicinesResidentsService.setMedicines(data, () => {
      if(attachments.length > 0){
        saveImage(attachments, idResident, 0);
      }else{
        setIsBlockSave(false);
        alertsApp.successAlert('Registrado con exito');
        cancelSave();
      }
    });
  }

  const saveImage = (attachments, id_resident, index) => {
    let arrayType = attachments[index].type.split('/');
    let lastIndexAttach = attachments.length - 1;
    let indexImage = index + 1;
    let nameImage = 'attachments/' + id_resident + '-' + indexImage + '.'+arrayType[1];
    firebase.storage().ref(nameImage).put(attachments[index].file)
    .then((snapshot) => {
      firebase.storage().ref(nameImage).getDownloadURL()
      .then((url) => {
        if(url){
          attachments[index].name = nameImage;
          attachments[index].file = '';
          attachments[index].path = url;
          attachments[index].create = Date.now();
          attachments[index].update = Date.now();
          
          if (index == lastIndexAttach) {
            updateAttachmentsResident(id_resident, attachments)
          } else {
            let nextIndex = index + 1
            saveImage(attachments, id_resident, nextIndex);
          }
        }
      })
    });
  }

  const updateAttachmentsResident = (id, attachments) => {
    let data = { attachments: attachments }
    residentsService.updateRegister(id, data, () => {
      setIsBlockSave(false);
      cancelSave();
      alertsApp.successAlert('Registrado con exito');
    })
  }

  const getRepresentativeRol = () => {
    rolesService.getRolByIdent('3').then(snapshot => {
      let rol = ''
      snapshot.forEach(doc => {
        rol = {
          id: doc.id,
          ident_rol: doc.data().ident_rol
        };
      })
      setRolRepresentative(rol);
    })
  }

  useEffect(() => {
    setKey('personal_info');
    getRepresentativeRol();
  }, [])

  return (
    <ResidentContext.Provider
      value={{
        user,
        setUser,
        resident,
        setResident,
        medicines,
        setMedicines
      }}
    >
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-resident"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Residentes </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Nuevo residente</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card-box">
                  <h3 className="card-title">Nuevo residente</h3>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => onSelectTab(k)}
                    className="mb-3"
                  >
                    <Tab eventKey="personal_info" title="Datos personales">
                      {key === 'personal_info' &&
                        <PersonalInformation origin="add" context={ResidentContext} />
                      }
                    </Tab>
                    <Tab eventKey="attachments" title="Adjuntos">
                      {key === 'attachments' &&
                          <Attachments origin="add" context={ResidentContext} />
                        }
                    </Tab>
                    <Tab eventKey="medicines" title="Medicamentos">
                      {key === 'medicines' &&
                        <MedicinesResident origin="add" context={ResidentContext} />
                      }
                    </Tab>
                  </Tabs>
                  <div className="row" style={{ justifyContent: 'end' }}>
                    <div className="col-12 col-md-3 col-xl-3">
                      <div className="doctor-submit">
                        <button
                          type="button"
                          className="btn btn-light submit-list-form me-2"
                          onClick={cancelSave}
                        >
                          Cancelar
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-md-3 col-xl-3">
                      <div className="doctor-submit">
                        <button
                          type="button"
                          className="btn btn-primary submit-list-form me-2"
                          onClick={save}
                          disabled={isBlockSave}
                        >
                          {isBlockSave &&
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          }
                          {isBlockSave ? ' Procesando...' : 'Registrar'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </ResidentContext.Provider>
  );
};

export default AddPatients;
