/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Table } from "antd";
import Header from '../Header';
import Sidebar from '../Sidebar';
import algoliasearch from "algoliasearch/lite";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { onShowSizeChange, itemRender } from '../Pagination'
import {
  blogimg10, imagesend, pdficon, pdficon3, pdficon4, plusicon, refreshicon, searchnormal, blogimg12,
  blogimg2, blogimg4, blogimg6, blogimg8
} from '../imagepath';
import { Link } from 'react-router-dom';

import algoliaConfig from "../../utils/Algolia";
import { alertsApp } from '../../utils/alertsApp';

import { residentsService } from '../../services/resident.service';
import { userService } from '../../services/user.service';

const client = algoliasearch(algoliaConfig.aplicationID, algoliaConfig.searchApiKey);
const indexSearch = client.initIndex(algoliaConfig.indexResident);

const PatientsList = () => {
  const [loader, setLoader] = useState(false);
  const [logged, setLogged] = useState({name: '', lastname: '', name_rol: ''});
  const [selectResident, setSelectResident] = useState('');
  const [datasource, setDatasource] = useState([]);
  const [search, setSearch] = useState('');
  const [totalRegisters, setTotalRegisters] = useState(0);
  const [page, setPage] = useState(1);
  const pageSize = 10;

  const column = [
    {
      title: "N° documento",
      dataIndex: "document",
      key: "document",
    },
    {
      title: "Residente",
      dataIndex: "resident",
      key: "resident",
      render: (text, record) => (
        <>
          <div>
            {record.name+' '+record.lastname}
          </div>
        </>
      )
    },
    {
      title: "Representante",
      dataIndex: "representative",
      key: "representative",
      render: (text, record) => (
        <>
          <div>
            <div>{record.representative}</div>
            <div style={{fontSize: '0.9em', color: '#b7b5b5'}}>{record.user.document}</div>
          </div>
        </>
      )
    },
    {
      title: "Cel. representante",
      dataIndex: "phone",
      key: "phone",
      render: (text, record) => (
        <>
          <div>{record.user.phone}</div>
        </>
      )
    },
    {
      title: "",
      dataIndex: "FIELD8",
      render: (text, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link className="dropdown-item" to="/edit-resident" onClick={selectRow(record)}>
                  <i className="far fa-edit me-2" /> Editar
                </Link>
                <Link className="dropdown-item" to="#" data-bs-toggle="modal" 
                  data-bs-target="#delete_patient"
                  onClick={selectRow(record)}
                >
                  <i className="fa fa-trash-alt m-r-5"></i> Inactivar
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  const onChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'search') {
      setSearch(value);
      getResidents(0, value, logged);
    }
  };

  const inactiveResident = () => {
    let data = {status: '0'}
    residentsService.updateRegister(selectResident.id, data, () => {
      alertsApp.successAlert('Auxiliar inactivado con exito');
      getResidents(page, search, logged);
    })
}

  const getResidents = (page, search, user) => {
    setDatasource([]);
    client.clearCache();
    indexSearch.search(search, {
      page: page,
      hitsPerPage: pageSize,
      filters: 'status:1'
    })
    .then((result) => {
      let residents = [];
      let num_residents = 0;
      if(!user.test){
          result.hits.forEach(hit => {
              if(!hit.test){
                  residents.push(hit);
              }
          })
          num_residents = residents.length;
      }else{
          residents = result.hits;
          num_residents = result.nbHits;
      }
      setTotalRegisters(num_residents);
      generateResident(residents);
    })
    .catch(err => {
      console.error(err);
    });
  }
  
  const generateResident = async (registers) => {
    let data = [];
    registers.forEach(doc => {
      let item = doc;
      item.id = doc.objectID
      data.push(item);
    })
    for (let index = 0; index < data.length; index++) {
      data[index].user = await userService.getUserAsync(data[index].id_representative);
    }
    setDatasource(data);
  }

  const selectRow = (row) => () => {
    setSelectResident(row);
    residentsService.setResident(row);
  }


  const reloadResidents = () => {
    getResidents(0, '', logged);
  }

  const changePageTable = (value) => {
    setPage(value);
    let page = value - 1;
    getResidents(page, search, logged);
  }

  useEffect(() => {
    let user = userService.getActiveUser();
    setLogged(user);
    residentsService.setResident(null);
    getResidents(0, '', user);
  }, [])

  return (
    <>
      <Header />
      <Sidebar id='menu-item2' id1='menu-items2' activeClassName='resident-list' />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">Residentes </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">Listado de residentes</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3>Listado de residentes</h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  name='search'
                                  value={search}
                                  className="form-control"
                                  placeholder="Search here"
                                  onChange={onChange}
                                />
                                <Link className="btn">
                                  <img
                                    src={searchnormal}
                                    alt="#"
                                  />
                                </Link>
                              </form>
                            </div>
                            <div className="add-group">
                              <Link
                                to="/add-resident"
                                className="btn btn-primary add-pluss ms-2"
                              >

                                <img src={plusicon} alt="#" />
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                                onClick={reloadResidents}
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-auto text-end float-end ms-auto download-grp">
                    <Link to="#" className=" me-2">
                      <img src={pdficon} alt="#" />
                    </Link>
                    <Link to="#" className=" me-2">
                    </Link>
                    <Link to="#" className=" me-2">
                      <img src={pdficon3} alt="#" />
                    </Link>
                    <Link to="#">
                      <img src={pdficon4} alt="#" />
                    </Link>
                  </div> */}
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      columns={column}
                      dataSource={datasource}
                      rowKey={(record) => record.id}
                      pagination={{
                        total: totalRegisters,
                        pageSize: pageSize,
                        current: page,
                        onChange: changePageTable
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="delete_patient" className="modal fade delete-modal" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src={imagesend} alt="#" width={50} height={46} />
              <h3>Esta seguro de eliminar el registro ?</h3>
              <div className="m-t-20">
                {" "}
                <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                  Cancelar
                </Link>
                <button type="submit" className="btn btn-danger" data-bs-dismiss="modal" onClick={inactiveResident}>
                  Eliminar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default PatientsList;
