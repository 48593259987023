/* eslint-disable no-unused-vars */
import React from "react";
import { Flex, Spin } from 'antd';

const contentStyle = {
  padding: 50,
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: 4,
};
const content = <div style={contentStyle} />;

const Preloader = () => {
  
  return (
    // <div className="box-preloader">
    //   <div>Cargando . . . </div>
    //   <div className="spinner-grow" style={{width: '3rem', height: '3rem'}} role="status">
    //     <span className="visually-hidden">Loading...</span>
    //   </div>
    // </div>
    <div className="box-preloader">
        <Spin tip="Cargando" size="large" fullscreen>
          {content}
        </Spin>
    </div>
  );
};

export default Preloader;
