import React from "react";
// eslint-disable-next-line no-unused-vars

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import Admin_Dashboard from "./components/Dashboard/Admin_Dashboard/Admin_Dashboard";
import Profile from "./components/pages/login/Profile";
import EditProfile from "./components/pages/login/EditProfile"; 
import Signout from "./components/pages/login/Signout";

import AssistantsList from "./components/assistants/AssistantsList";
import NewAssistants from "./components/assistants/NewAssistants";
import EditAssistants from "./components/assistants/EditAssistants";

import PatientsList from "./components/patients/PatientsList";
import AddPatients from "./components/patients/AddPatients";
import EditPatients from "./components/patients/EditPatients";

import VitalSigns from "./components/vital_signs/VitalSigns";

import Chat from "./components/Chat/Chat";

//Accounts
const AppAdmin = () => {
  // eslint-disable-next-line no-unused-vars
  // const config = "/react/template"
  return (
    <>
      {/* <BrowserRouter basename="/react/template"> */}
      <BrowserRouter basename="">
        <Routes>
          {/* Dashboard */}
          <Route path="/" element={<Admin_Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/logout" element={<Signout />} />
          {/* Assistant  */}
          <Route path="/assistants" element={<AssistantsList />} />
          <Route path="/add-assistant" element={<NewAssistants />} />
          <Route path="/edit-assistant" element={<EditAssistants />} />
          {/* Residents */}
          <Route path="/residents" element={<PatientsList />} />
          <Route path="/add-resident" element={<AddPatients />} />
          <Route path="/edit-resident" element={<EditPatients />} />
          {/* VitalSigns */}
          <Route path="/vitals-signs" element={<VitalSigns />} />
          {/* Chat */}
          <Route path="/chat" element={<Chat />} />
        </Routes>
      </BrowserRouter>
      <div className="sidebar-overlay"></div>
      <ToastContainer />
    </>
  );
};

export default AppAdmin;
