import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import BaseService from './base.service';

class VitalSignsService extends BaseService {

    constructor(){
        super();
        this.collection = 'vital_signs';
    }

    async getVitalSignsAsync(id){
        let query = await firebase.firestore()
            .collection(this.collection)
            .where('resident', '==', id)
            .orderBy('create_date', 'desc')
            .limit(5)
            .get();
        return query;
    }

    getVitalSignsResident(id){
        let query = firebase.firestore()
            .collection(this.collection)
            .where('resident', '==', id)
            .orderBy('create_date', 'desc')
            .limit(5);
        return query;
    }
}

export const vitalSignsService = new VitalSignsService();