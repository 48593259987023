const algoliaConfig = {
    aplicationID: '5WVY6YITUL',
    searchApiKey: '670a5495a16a206a503fad73ef2ab572',
    indexUser: 'user_geriatrico_index',
    indexResident: 'residents_geriatrico_index',
    indexMedicinesRes: 'medicines_res_geriatrico_index',
    indexMedicines: 'medicines_geriatrico_index',
    indexVitalSigns: 'vital_signs_geriatrico_index',
    indexCountries: 'countries_geriatrico_index',
}
  
  export default algoliaConfig;