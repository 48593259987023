/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { imguser, warning_icon, success_icon, error_icon } from "../../imagepath";
import Select from "react-select";
import { Switch } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import { functions } from '../../../utils/functions';
import { alertsApp } from "../../../utils/alertsApp";
import { userService } from "../../../services/user.service";

const EditProfile = () => {
  const [user, setUser] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isCambioDeClave, setIsCambioDeClave] = useState(false);
  const [isValidPass, setIsValidPass] = useState(false);
  const [isBlockSave, setIsBlockSave] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState({ pass: true, confirm: true });
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  // const [selectedOption, setSelectedOption] = useState(null);
  const [imgUser, setImgUser] = useState(null);
  const [gender, setGender] = useState([
    { value: "-", label: "Seleccionar genero" },
    { value: "Hombre", label: "Hombre" },
    { value: "Mujer", label: "Mujer" },
  ]);

  const [keysValidationsPass, setKeysValidationsPass] = useState(['upperLower', 'numbers', 'spetialChars', 'lengthChars']);
  const [validationsPass, setValidationsPass] = useState({
    upperLower: {
      text: 'La contraseña debe contener letras mayúsculas y minúsculas',
      image: warning_icon,
      color: '#ff9e55'
    },
    numbers: {
      text: 'La contraseña debe contener al menos un número',
      image: warning_icon,
      color: '#ff9e55'
    },
    spetialChars: {
      text: 'La contraseña debe contener al menos uno de los siguientes caracteres especial @, -, $, #, _, |',
      image: warning_icon,
      color: '#ff9e55'
    },
    lengthChars: {
      text: 'La contraseña debe contener mínimo 10 caracteres',
      image: warning_icon,
      color: '#ff9e55'
    },
  })

  const onChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name === 'isCambioDeClave') {
      setIsCambioDeClave(value);
    } else {
      if (name === 'password') {
        validatePass(value);
        setPassword(value);
      } else {
        let data = { ...user };
        if (name === 'document' || name === 'phone') {
          data[name] = functions.validNumber(value);
        }else{
          data[name] = value;
        }
        setUser(data);
      }
    }
  };

  const validatePass = (value) => {
    const lettersUpper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lettersLower = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const spetialChars = "@-$#_|";
    let countValid = 0;
    if (value) {
      if (functions.validCharactersIn(value, lettersUpper) && functions.validCharactersIn(value, lettersLower)) {
        setValidation('upperLower', 'success');
      } else {
        setValidation('upperLower', 'error');
        countValid++;
      }

      if (functions.validCharactersIn(value, numbers)) {
        setValidation('numbers', 'success');
      } else {
        setValidation('numbers', 'error');
        countValid++;
      }

      if (functions.validCharactersIn(value, spetialChars)) {
        setValidation('spetialChars', 'success');
      } else {
        setValidation('spetialChars', 'error');
        countValid++;
      }

      if (value.length >= 10) {
        setValidation('lengthChars', 'success');
      } else {
        setValidation('lengthChars', 'error');
        countValid++;
      }
    } else {
      keysValidationsPass.forEach(key => {
        setValidation(key, 'warning');
      })
      countValid++;
    }

    if (countValid == 0) {
      setIsValidPass(true);
    } else {
      setIsValidPass(false);
    }
  }

  const setValidation = (section, type) => {
    let validationsInput = { ...validationsPass };
    if (type === 'success') {
      validationsInput[section].color = '#01ecae';
      validationsInput[section].image = success_icon;
    }
    if (type === 'warning') {
      validationsInput[section].color = '#ff9e55';
      validationsInput[section].image = warning_icon;
    }
    if (type === 'error') {
      validationsInput[section].color = '#ff5352';
      validationsInput[section].image = error_icon;
    }
    setValidationsPass(validationsInput);
  }

  const getUserLogged = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user !== null) {
        let user = await userService.getUserActive();
        setUser(user);
        // let dataSelect = { value: user.gender, label: user.gender };
        // setSelectedOption(dataSelect);
        if(user.img){
          getImagesStorage(user);
        }else{
          setLoader(true);
        }
      }
    })
  }

  const getImagesStorage = (user) => {
    let nameImage = 'images/'+user.document+'.png';
    firebase.storage().ref(nameImage).getDownloadURL()
    .then((url) => {
      if(url){
        setImgUser(url);
      }
      setLoader(true);
    })
  }

  const handleImageChange = (event) => {
    try {
      Resizer.imageFileResizer(
        event.target.files[0],
        400,
        400,
        "png",
        100,
        0,
        (uri) => {
          setImgUser(uri);
        },
        "base64",
        200,
        200
      );
    } catch (err) {
      console.error(err);
    }
  }

  const togglePasswordVisibility = (origin) => () => {
    let visible = { ...passwordVisible };
    if (origin == 'P') {
      visible.pass = !visible.pass
    } else {
      visible.confirm = !visible.confirm
    }
    setPasswordVisible(visible);
  };

  const save = () => {
    setIsBlockSave(true);
    let valid = validFields();
    if(valid.valid){
      updateUser();
    }else{
      alertsApp.errorAlert(valid.message);
      setIsBlockSave(false);
    }
  }

  const validFields = () => {
    let result = {valid: true, message: ''};
    if(!user.name || !user.lastname || !user.document || !user.phone){
      result.valid = false;
      result.message = 'Los campos del formulario son obligatorios';
    }

    if(isCambioDeClave){
      if(password == ''){
        result.valid = false;
        result.message = 'La contraseña se encuentra vacia';
      }else{
        if(!isValidPass){
          result.valid = false;
          result.message = 'La contraseña es incorrecta, debe cumplir con todos los requerimientos';
        }else{
          if(confirmPassword != password){
            result.valid = false;
            result.message = 'Confirmación de contraseña incorrecta';
          }
        }
      }
    }
    return result;
  }

  const updateUser = () => {
    user.update_date = Date.now();
    userService.updateRegister(firebase.auth().currentUser.uid, user, () => {
      if (imgUser != null){
        let reg = new RegExp('http');
        if(!reg.test(imgUser)){
            saveImage(user.document, imgUser);
        }else{
          updateAuthentication('');
        }
      }else{
        updateAuthentication('');
      }
    })
  }

  const saveImage = async (documentUser, image) => {
    const response = await fetch(image);
    const blob = await response.blob();
    let nameImage = 'images/'+documentUser+'.png';
    firebase.storage().ref(nameImage).put(blob)
    .then((snapshot)=>{
        snapshot.ref.getDownloadURL().then(function (url) {  // Now I can use url
          updateUserImg(url);
        });
    });
  }

  const updateUserImg = (url) => {
    let data = {img: url}
    userService.updateRegister(firebase.auth().currentUser.uid, data, () => {
      updateAuthentication(url);
    })
  }

  const updateAuthentication = (url) => {
    let name = user.name+' '+user.lastname;
    let data = {displayName: name};
    if(url){
      data = {displayName: name, photoURL: url};
    }
    firebase.auth().currentUser.updateProfile(data).then(() => {
      if (isCambioDeClave) {
        updatePassword()
      } else {
        alertsApp.successAlert('Actualizado con exito');
        setIsBlockSave(false);
        initForm();
      }
    }, (error) => {
        console.error(error);
    });
}

  const updatePassword = () => {
    firebase.auth().currentUser.updatePassword(password)
    .then(res => {
      alertsApp.successAlert('Actualizado con exito');
      setIsBlockSave(false);
      initForm();
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      if(errorCode === 'auth/requires-recent-login'){
        alertsApp.errorAlert('Esta operación es confidencial y requiere autenticación reciente. Inicie sesión nuevamente antes de volver a intentar esta solicitud.')
      }else{
        alertsApp.errorAlert('No se pudo cambiar la contraseña');
      }
      setIsBlockSave(false);
    })
  }

  const initForm = () => {
    setUser(null);
    setLoader(false);
    setIsCambioDeClave(false);
    setIsValidPass(false);
    setIsBlockSave(false);
    setPasswordVisible({ pass: true, confirm: true });
    setPassword('');
    setConfirmPassword('');
    keysValidationsPass.forEach(key => {
      setValidation(key, 'warning');
    })
    getUserLogged();
  }

  useEffect(() => {
    initForm();
  }, [])

  return (
    <>
      <Header />
      <Sidebar />
      <>
        {loader ? (
          <div className="page-wrapper">
            <div className="content">
              {/* Page Header */}
              <div className="page-header">
                <div className="row">
                  <div className="col-sm-12">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#">Dashboard </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <i className="feather-chevron-right">
                          <FeatherIcon icon="chevron-right" />
                        </i>
                      </li>
                      <li className="breadcrumb-item active">Edit Perfil</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              <form>
                <div className="card-box">
                  {/* <h3 className="card-title">Basic Informations</h3> */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="profile-img-wrap">
                        <img className="inline-block" src={imgUser ? imgUser : imguser} alt="user" />
                        <div className="fileupload btn">
                          <span className="btn-text">edit</span>
                          <input className="upload" type="file" accept="image/*" onChange={(event) => handleImageChange(event)} />
                        </div>
                      </div>
                      <div className="profile-basic">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group local-forms">
                              <label className="focus-label">Cédula <span className="login-danger">*</span></label>
                              <input
                                type="text"
                                name="document"
                                className="form-control floating"
                                maxLength={10}
                                value={user.document}
                                onChange={onChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group local-forms">
                              <label className="focus-label">Email</label>
                              <input
                                type="text"
                                name="email"
                                className="form-control floating"
                                defaultValue={user.email}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group local-forms">
                              <label className="focus-label">Apellido <span className="login-danger">*</span></label>
                              <input
                                type="text"
                                name="lastname"
                                className="form-control floating"
                                value={user.lastname}
                                onChange={onChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group local-forms">
                              <label className="focus-label">Nombre <span className="login-danger">*</span></label>
                              <input
                                type="text"
                                name="name"
                                className="form-control floating"
                                value={user.name}
                                onChange={onChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group local-forms">
                              <label className="focus-label">Celular <span className="login-danger">*</span></label>
                              <input
                                type="text"
                                name="phone"
                                className="form-control floating"
                                maxLength={10}
                                value={user.phone}
                                onChange={onChange}
                              />
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                          <div className="form-group local-forms ">
                            <label className="focus-label">Birth Date</label>
                            <div className="cal-icon">
                            <DatePicker
                              className="form-control datetimepicker"
                              onChange={onChange}
                              suffixIcon={null}
                            />
                            </div>
                          </div>
                        </div> */}
                          {/* <div className="col-md-6">
                          <div className="form-group local-forms">
                            <label className="focus-label">Genero</label>
                            <Select
                              name="gender"
                              defaultValue={selectedOption}
                              onChange={setSelectedOption}
                              options={gender}
                            />
                          </div>
                        </div> */}
                        </div>
                        <hr />
                        <div className="row" style={{ marginBottom: 16 }}>
                          <div className="col-md-6">
                            <FormControlLabel
                              control={
                                <Switch value={isCambioDeClave} color='primary' onChange={onChange} name='isCambioDeClave' />
                              }
                              label="¿Desea cambiar la clave?"
                              labelPlacement='start'

                            />
                          </div>
                          <div className="col-md-6"></div>
                        </div>
                        {isCambioDeClave &&
                          <>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group local-forms">
                                  <label className="focus-label">Contraseña</label>
                                  <input
                                    type={passwordVisible.pass ? 'password' : ''}
                                    name="password"
                                    className="form-control floating"
                                    value={password}
                                    onChange={onChange}
                                  />
                                  <span
                                    className="toggle-password"
                                    onClick={togglePasswordVisibility('P')}
                                  >
                                    {passwordVisible.pass ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group local-forms">
                                  <label className="focus-label">Confirmar Contraseña</label>
                                  <input
                                    type={passwordVisible.confirm ? 'password' : ''}
                                    name="confirmPassword"
                                    className="form-control floating"
                                    value={confirmPassword}
                                    // onChange={onChange}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                  />
                                  <span
                                    className="toggle-password"
                                    onClick={togglePasswordVisibility('C')}
                                  >
                                    {passwordVisible.confirm ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              {keysValidationsPass.map((itemKey, indexK) => (
                                <div className="box-message-password col-md-12" key={'valid' + indexK}>
                                  <img src={validationsPass[itemKey].image} width="13" style={{ marginRight: 8 }} />
                                  <span style={{ color: validationsPass[itemKey].color, lineHeight: '13px' }}>
                                    {validationsPass[itemKey].text}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="card-box">
                <h3 className="card-title">Contact Informations</h3>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group local-forms">
                      <label className="focus-label">Address</label>
                      <input
                        type="text"
                        className="form-control floating"
                        defaultValue="4487 Snowbird Lane"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <label className="focus-label">State</label>
                      <input
                        type="text"
                        className="form-control floating"
                        defaultValue="New York"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <label className="focus-label">Country</label>
                      <input
                        type="text"
                        className="form-control floating"
                        defaultValue="United States"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <label className="focus-label">Pin Code</label>
                      <input
                        type="text"
                        className="form-control floating"
                        defaultValue={10523}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <label className="focus-label">Phone Number</label>
                      <input
                        type="text"
                        className="form-control floating"
                        defaultValue="631-889-3206"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-box">
                <h3 className="card-title">Education Informations</h3>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <label className="focus-label">Institution</label>
                      <input
                        type="text"
                        className="form-control floating"
                        defaultValue="Oxford University"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <label className="focus-label">Subject</label>
                      <input
                        type="text"
                        className="form-control floating"
                        defaultValue="Computer Science"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <label className="focus-label">Starting Date</label>
                      <div className="cal-icon">
                        <DatePicker
                          className="form-control datetimepicker"
                          onChange={onChange}
                          suffixIcon={null}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <label className="focus-label">Complete Date</label>
                      <div className="cal-icon">
                        <DatePicker
                          className="form-control datetimepicker"
                          onChange={onChange}
                          suffixIcon={null}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <label className="focus-label">Degree</label>
                      <input
                        type="text"
                        className="form-control floating"
                        defaultValue="BE Computer Science"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <label className="focus-label">Grade</label>
                      <input
                        type="text"
                        className="form-control floating"
                        defaultValue="Grade A"
                      />
                    </div>
                  </div>
                </div>
                <div className="add-more">
                  <Link to="#" className="btn btn-primary">
                    <i className="fa fa-plus" /> Add More Institute
                  </Link>
                </div>
              </div>
              <div className="card-box">
                <h3 className="card-title">Experience Informations</h3>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <label className="focus-label">Company Name</label>
                      <input
                        type="text"
                        className="form-control floating"
                        defaultValue="Digital Devlopment Inc"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <label className="focus-label">Location</label>
                      <input
                        type="text"
                        className="form-control floating"
                        defaultValue="United States"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <label className="focus-label">Job Position</label>
                      <input
                        type="text"
                        className="form-control floating"
                        defaultValue="Web Developer"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <label className="focus-label">Period From</label>
                      <div className="cal-icon">
                        <DatePicker
                          className="form-control datetimepicker"
                          onChange={onChange}
                          suffixIcon={null}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group local-forms">
                      <label className="focus-label">Period To</label>
                      <div className="cal-icon">
                        <DatePicker
                          className="form-control datetimepicker"
                          onChange={onChange}
                          suffixIcon={null}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="add-more">
                  <Link to="#" className="btn btn-primary">
                    <i className="fa fa-plus" /> Add More Experience
                  </Link>
                </div>
              </div> */}
                <div className="text-center m-t-20" style={{paddingBottom: 20}}>
                  <button className="btn btn-primary submit-btn" type="button" onClick={save} disabled={isBlockSave}>
                    {isBlockSave &&
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    }
                    {isBlockSave ? ' Procesando...' : 'Guardar'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div>Cargando...</div>
        )}
      </>
    </>
  );
};

export default EditProfile;
