/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, createContext } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Header from "../Header";
import Sidebar from "../Sidebar";

import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";

import { alertsApp } from '../../utils/alertsApp';
import { userService } from "../../services/user.service";
import GeneralInformation from "./GeneralInformation";
import Attachments from "./Attachments";

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const AssistantContext = createContext(null);

const EditAssistants = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState('general_info');
  const [user, setUser] = useState({
    document: '',
    name: '',
    lastname: '',
    email: '',
    phone: '',
    attachments: [],
    status: '',
    create_date: '',
    update_date: ''
  });
  const [isBlockSave, setIsBlockSave] = useState(false);

  const onSelectTab = (key) => {
    setKey(key)
  }

  const cancelSave = () => {
    setIsBlockSave(false);
    setUser({
      document: '',
      name: '',
      lastname: '',
      email: '',
      phone: '',
      attachments: [],
      status: '',
      create_date: '',
      update_date: ''
    })
    navigate('/assistants');
  }

  const save = () => {
    setIsBlockSave(true);
    user.update_date = Date.now();
    if (!user.name || !user.lastname || !user.document || !user.phone) {
      setIsBlockSave(false);
      alertsApp.errorAlert('Los campos del formulario son obligatorios');
    } else {
      let data = { ...user };
      delete data.id;
      delete data.email;
      updateUser(user.id, data);
    }
  }

  const updateUser = (id, data) => {
    let attachments = [...data.attachments]
    delete data.attachments;
    userService.updateRegister(id, data, () => {
      if(attachments.length > 0){
        saveImage(attachments, id, 0);
      }else{
        alertsApp.successAlert('Registrado con exito');
        navigate('/assistants');
      }
    });
  }

  const saveImage = (attachments, id_user, index) => {
    let lastIndexAttach = attachments.length - 1;
    if(attachments[index].name == 'nuevo'){
      let arrayType = attachments[index].type.split('/');
      let indexImage = attachSequential(attachments);
      let nameImage = 'attachments/' + id_user + '-' + indexImage + '.' + arrayType[1];
      firebase.storage().ref(nameImage).put(attachments[index].file)
        .then((snapshot) => {
          firebase.storage().ref(nameImage).getDownloadURL()
            .then((url) => {
              if (url) {
                attachments[index].name = nameImage;
                attachments[index].file = '';
                attachments[index].path = url;
                attachments[index].update = Date.now();
  
                if (index == lastIndexAttach) {
                  updateAttachmentsUser(id_user, attachments)
                } else {
                  let nextIndex = index + 1
                  saveImage(attachments, id_user, nextIndex);
                }
              }
            })
        });
    }else{
      if (index == lastIndexAttach) {
        updateAttachmentsUser(id_user, attachments)
      } else {
        let nextIndex = index + 1
        saveImage(attachments, id_user, nextIndex);
      }
    }
  }

  const attachSequential = (attachments) => {
    let sequential = []
    let result = 0
    if (attachments.length > 0) {
      attachments.forEach(item => {
        if (item.name !== 'nuevo') {
          let arrayName = item.name.split('-')
          let lastIndex = arrayName.length - 1
          sequential.push(parseInt(arrayName[lastIndex]))
        }else {
          sequential.push(0)
        }
      })
      result = Math.max(...sequential) + 1
    }

    return result
  }

  const updateAttachmentsUser = (id, attachments) => {
    let data = { attachments: attachments }
    userService.updateRegister(id, data, () => {
      setIsBlockSave(false);
      userService.setDataUser(null);
      let interval = setInterval(() => {
        alertsApp.successAlert('Registrado con exito');
        clearInterval(interval);
        navigate('/assistants');
      }, 1000)
    })
  }

  useEffect(() => {
    let user = userService.getDataUser();
    if (user != null) {
      setUser(user);
    }
  }, [])

  return (
    <AssistantContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      <Header />
      <Sidebar id="menu-item1" id1="menu-items1" activeClassName="edit-assistant" />
      <>
        <div>
          <div className="page-wrapper">
            <div className="content">
              {/* Page Header */}
              <div className="page-header">
                <div className="row">
                  <div className="col-sm-12">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#">Auxiliares</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <i className="feather-chevron-right">
                          <FeatherIcon icon="chevron-right" />
                        </i>
                      </li>
                      <li className="breadcrumb-item active">Editar auxiliar</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              <div className="row">
                <div className="col-sm-12">
                  <div className="card-box">
                    <h3 className="card-title">Editar auxiliar</h3>
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={(k) => onSelectTab(k)}
                      className="mb-3"
                    >
                      <Tab eventKey="general_info" title="Datos personales">
                        {key === 'general_info' &&
                          <GeneralInformation origin="edit" context={AssistantContext} />
                        }
                      </Tab>
                      <Tab eventKey="attachments" title="Adjuntos">
                        {key === 'attachments' &&
                          <Attachments origin="edit" context={AssistantContext} />
                        }
                      </Tab>
                    </Tabs>
                    <div className="row" style={{ justifyContent: 'end' }}>
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="doctor-submit">
                          <button
                            type="button"
                            className="btn btn-light submit-list-form me-2"
                            onClick={cancelSave}
                          >
                            Cancelar
                          </button>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 col-xl-3">
                        <div className="doctor-submit">
                          <button
                            type="button"
                            className="btn btn-primary submit-list-form me-2"
                            onClick={save}
                            disabled={isBlockSave}
                          >
                            {isBlockSave &&
                              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            }
                            {isBlockSave ? ' Procesando...' : 'Registrar'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </AssistantContext.Provider>
  );
};

export default EditAssistants;
