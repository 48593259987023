/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { blog, dashboard, doctor, vital_signs, logout, menuicon04, menuicon06, menuicon08, menuicon09, menuicon10, menuicon11, menuicon12, menuicon14, menuicon15, menuicon16, patients, sidemenu } from './imagepath';
import Scrollbars from "react-custom-scrollbars-2";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { userService } from "../services/user.service";

const Sidebar = (props) => {
  const [logged, setLogged] = useState({name: '', lastname: '', name_rol: ''});
  const [sidebar, setSidebar] = useState("");

  const handleClick = (e, item, item1, item3) => {
    const div = document.querySelector(`#${item}`);
    const ulDiv = document.querySelector(`.${item1}`);
    e?.target?.className ? ulDiv.style.display = 'none' : ulDiv.style.display = 'block'
    e?.target?.className ? div.classList.remove('subdrop') : div.classList.add('subdrop');
  }

  const getUserLogged = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if(user !== null){
        let user = userService.getActiveUser();
        setLogged(user);
        
      }
    })
  }

  useEffect(() => {
    getUserLogged();
    let interval = setInterval(() => {
      if (props?.id && props?.id1) {
        const ele = document.getElementById(`${props?.id}`);
        handleClick(ele, props?.id, props?.id1);
      }
      clearInterval(interval);
    }, 500)
  }, [])


  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  return (
    <>
      <div className="sidebar" id="sidebar">
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="95vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}
        >
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu"
              onMouseLeave={expandMenu}
              onMouseOver={expandMenuOpen}
            >
              <ul>
                <li className="menu-title">Main</li>
                {logged.name_rol === 'Administrador' &&
                  <li>
                    <Link to="/">
                      <span className="menu-side">
                        <img src={dashboard} alt="" />
                      </span>{" "}
                      <span>Dashboard</span>
                    </Link>
                  </li>
                }
                {logged.name_rol !== 'Administrador' &&
                  <li>
                    <Link to="/">
                      <span className="menu-side">
                        <img src={dashboard} alt="" />
                      </span>{" "}
                      <span>Inicio</span>
                    </Link>
                  </li>
                }
                {logged.name_rol === 'Administrador' &&
                  <li className="submenu">
                    <Link to="#" id="menu-item1" onClick={(e) => {
                      // setSidebar('Doctors')
                      handleClick(e, "menu-item1", "menu-items1")
                    }}>
                      <span className="menu-side">
                        <img src={doctor} alt="" />
                      </span>{" "}
                      <span> Auxiliares </span> <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: sidebar === 'Doctors' ? 'block' : 'none' }} className="menu-items1">
                      <li>
                        <Link className={props?.activeClassName === 'assistants-list' ? 'active' : ''} to="/assistants">Lista de auxiliares</Link>
                      </li>
                      <li>
                        <Link className={props?.activeClassName === 'add-assistant' ? 'active' : ''} to="/add-assistant">Nuevo auxiliar</Link>
                      </li>
                      {/* <li>
                        <Link className={props?.activeClassName === 'edit-assistant' ? 'active' : ''} to="/edit-assistant">Editar auxiliar</Link>
                      </li> */}
                    </ul>
                  </li>
                }
                {logged.name_rol === 'Administrador' &&
                  <li className="submenu">
                    <Link to="#" id="menu-item2" onClick={(e) => handleClick(e, "menu-item2", "menu-items2")}>
                      <span className="menu-side">
                        <img src={patients} alt="" />
                      </span>{" "}
                      <span>Residentes </span> <span className="menu-arrow" />
                    </Link>
                    <ul style={{ display: "none" }} className="menu-items2">
                      <li>
                        <Link className={props?.activeClassName === 'resident-list' ? 'active' : ''} to="/residents">Lista de residentes</Link>
                      </li>
                      <li>
                        <Link className={props?.activeClassName === 'add-resident' ? 'active' : ''} to="/add-resident">Nuevo residente</Link>
                      </li>
                      {/* <li>
                        <Link className={props?.activeClassName === 'edit-resident' ? 'active' : ''} to="/edit-resident">Editar residente</Link>
                      </li> */}
                    </ul>
                  </li>
                }
                <li>
                  <Link to="/vitals-signs">
                    <span className="menu-side">
                      <img src={vital_signs} alt="" />
                    </span>{" "}
                    <span>Historial Signos Vitales</span>
                  </Link>
                </li>
                {/* {logged.name_rol !== 'Administrador' &&
                } */}
              </ul>
              <div className="logout-btn">
                <Link to="/logout">
                  <span className="menu-side">
                    <img src={logout} alt="" />
                  </span>{" "}
                  <span style={{fontSize: 12.6}}>Salir</span>
                </Link>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  )
}
export default Sidebar
