const firebaseConfig = {
  apiKey: "AIzaSyBpRcGpsVhF3fbXaSTDDgz8IgyB0SK4yWs",
  authDomain: "dolcevita-abfcb.firebaseapp.com",
  projectId: "dolcevita-abfcb",
  storageBucket: "dolcevita-abfcb.appspot.com",
  messagingSenderId: "461875881235",
  appId: "1:461875881235:web:58fa27603465ce273e26e6",
  measurementId: "G-BTYJF44PFC"
}

export default firebaseConfig;
