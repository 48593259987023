/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Table } from "antd";
import Header from '../Header';
import Sidebar from '../Sidebar';
import { Grid } from '@mui/material';
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Select, DatePicker, Flex, Spin } from 'antd';
// import { Alert, Flex, Spin, Switch } from 'antd';
import algoliasearch from "algoliasearch/lite";
import dayjs from 'dayjs';
import {
    refreshicon,
} from "../imagepath";
import { Link } from 'react-router-dom';

import algoliaConfig from "../../utils/Algolia";
import { functions } from '../../utils/functions';
import Preloader from '../Preloader';

import { userService } from '../../services/user.service';
import { residentsService } from '../../services/resident.service';

const client = algoliasearch(algoliaConfig.aplicationID, algoliaConfig.searchApiKey);
const indexResidentSearch = client.initIndex(algoliaConfig.indexResident);
const indexVitalSingsSearch = client.initIndex(algoliaConfig.indexVitalSigns);
const indexUsersSearch = client.initIndex(algoliaConfig.indexUser);

const { RangePicker } = DatePicker;

const VitalSigns = () => {
    const [loader, setLoader] = useState(false);
    const [loaderData, setLoaderData] = useState(false);
    const [datasource, setDatasource] = useState([]);
    const [userLogged, setUserLogged] = useState('');
    const [residentsAssistant, setResidentsAssistant] = useState([]);
    const [assistants, setAssistant] = useState([]);
    const [residentsUser, setResidentsUser] = useState([]);
    const [searchResident, setSearchResident] = useState('');
    const [searchAssistant, setSearchAssistant] = useState('');
    const [searchDates, setSearchDates] = useState([]);
    const [totalRegisters, setTotalRegisters] = useState(0);
    const [page, setPage] = useState(1);
    const [widthColumnMd, setWidthColumnMd] = useState(5);
    const pageSize = 10;

    const column = [
        {
            title: "Residente",
            dataIndex: "name_resident",
            sorter: (a, b) => a.name_resident.length - b.name_resident.length,
        },
        {
            title: "Fecha",
            dataIndex: "date_string",
            sorter: (a, b) => a.create_date - b.create_date,
        },
        {
            title: "Tensión Arterial",
            dataIndex: "t_a",
            key: "t_a",
            align: 'center',
        },
        {
            title: "F. Respiratoria",
            dataIndex: "fr_xl",
            key: "fr_xl",
            align: 'center',
        },
        {
            title: "Temperatura",
            dataIndex: "temp",
            key: "temp",
            align: 'center',
        },
        {
            title: "Saturación",
            dataIndex: "sat",
            key: "sat",
            align: 'center',
        },
    ];

    const columnAdmin = [
        {
            title: "Auxiliar",
            dataIndex: "assistant_name",
            sorter: (a, b) => a.assistant_name.length - b.assistant_name.length,
        },
        {
            title: "Residente",
            dataIndex: "name_resident",
            sorter: (a, b) => a.name_resident.length - b.name_resident.length,
        },
        {
            title: "Fecha",
            dataIndex: "date_string",
            sorter: (a, b) => a.create_date - b.create_date,
        },
        {
            title: "Tensión A.",
            dataIndex: "t_a",
            key: "t_a",
            align: 'center',
        },
        {
            title: "F. Resp.",
            dataIndex: "fr_xl",
            key: "fr_xl",
            align: 'center',
        },
        {
            title: "Temp.",
            dataIndex: "temp",
            key: "temp",
            align: 'center',
        },
        {
            title: "Sat.",
            dataIndex: "sat",
            key: "sat",
            align: 'center',
        },
    ];

    const onChangeDate = (date, dateString) => {
        setSearchDates(dateString);
        getVitalSigns(0, searchResident, userLogged, dateString, searchAssistant);
    };

    const onChangeSelect = (value) => {
        setSearchResident(value);
        getVitalSigns(0, value, userLogged, searchDates, searchAssistant);
    }

    const onChangeSelectAssintant = (value) => {
        setSearchAssistant(value);
        getVitalSigns(0, searchResident, userLogged, searchDates, value);
    }

    const handleSearchResident = (newValue) => {
        getResidents(newValue);
    };

    const handleSearchAssintant = (newValue) => {
        getAssistants(newValue);
    };

    const reloadVitalSigns = () => {
        setSearchResident('');
        setSearchAssistant('');
        let dates = generateDatesEmpty();
        getVitalSigns(0, '', userLogged, dates, searchAssistant);
    }

    const changePageTable = (value) => {
        setPage(value);
        let page = value - 1;
        getVitalSigns(page, '', userLogged, searchDates, searchAssistant);
    }

    const getUserActive = async () => {
        let user = await userService.getUserActive();
        setUserLogged(user);
        setLoader(true);
        setWidthColumnMd(5);
        
        if (user.ident_rol == 1) {
            setWidthColumnMd(3);
        }
        if (user.ident_rol == 2 || user.ident_rol == 1) {
            let dates = generateDatesEmpty();
            getVitalSigns(0, '', user, dates, searchAssistant);
        }
        if (user.ident_rol == 3) {
            getResidentsUser(user);
        }
    }

    const getVitalSigns = (page, resident, user, dates, assistant) => {
        setDatasource([]);
        setLoaderData(false);
        client.clearCache();
        let facets = [];
        let filters = '';
        if (user.ident_rol == 1) {
            facets[0] = `resident: ${resident}`;
            facets[1] = `user_create: ${assistant}`;
            let timestampIni = functions.stringToTimestamp(dates[0] + ' 00:00:00');
            let timestampEnd = functions.stringToTimestamp(dates[1] + ' 23:59:59');
            filters = `(create_date >= ${timestampIni} AND create_date <= ${timestampEnd})`;
        }
        if (user.ident_rol == 2) {
            facets[0] = `resident: ${resident}`;
        }
        if (user.ident_rol == 3) {
            facets[0] = `representative: ${user.id}`;
            let timestampIni = functions.stringToTimestamp(dates[0] + ' 00:00:00');
            let timestampEnd = functions.stringToTimestamp(dates[1] + ' 23:59:59');
            filters = `(create_date >= ${timestampIni} AND create_date <= ${timestampEnd})`;
        }

        indexVitalSingsSearch.search('', {
            page: page,
            hitsPerPage: pageSize,
            filters: filters,
            facetFilters: facets
        })
            .then(async (result) => {
                let vital_signs = [];
                let num_vital_signs = 0;
                if(!user.test){
                    for (let indexVS = 0; indexVS < result.hits.length; indexVS++) {
                        let hit = result.hits[indexVS];
                        let resident = await residentsService.getResidentAsync(hit.resident);
                        if(!resident.test){
                            vital_signs.push(hit);
                        }
                    }
                    num_vital_signs = vital_signs.length;
                }else{
                    if (user.ident_rol == 1) {
                        for (let indexVS = 0; indexVS < result.hits.length; indexVS++) {
                            let hit = result.hits[indexVS];
                            let assistant = await userService.getUserAsync(hit.user_create);
                            hit.assistant_name = assistant.name+' '+assistant.lastname;
                            vital_signs.push(hit);
                        }
                        num_vital_signs = result.nbHits;
                    }else{
                        vital_signs = result.hits;
                        num_vital_signs = result.nbHits;
                    }
                }
                setTotalRegisters(num_vital_signs);
                generateVitalSigs(vital_signs);
                setLoaderData(true);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const generateVitalSigs = (registers) => {
        let vitalSigns = [];
        registers.forEach(doc => {
            let item = doc
            item.id = doc.objectID;
            item.date_string = functions.timestampToString(item.create_date, 'YYYY-mm-dd h:i:s');
            vitalSigns.push(item);
        })
        setDatasource(vitalSigns);
    }

    const getResidentsUser = (user) => {
        residentsService.getResidentsUser(user.id).get().then(snapshot => {
            let residents = []
            snapshot.forEach(doc => {
                let label = doc.data().name+' '+doc.data().lastname;
                let item = { value: doc.id, label: label };
                residents.push(item);
            })
            setResidentsUser([]);
            if (residents.length > 1) {
                setResidentsUser(residents);
            }
            let dates = generateDatesEmpty();
            getVitalSigns(0, '', user, dates, searchAssistant);
        })
    }
    
    const getResidents = (search) => {
        client.clearCache();
        indexResidentSearch.search(search, {
            filters: 'status:1'
        })
            .then(({ hits }) => {
                let residents = [];
                hits.forEach(item => {
                    item.id = item.objectID;
                    item.text = item.name + ' ' + item.lastname;
                    residents.push(item);
                })
                setResidentsAssistant(residents);
            })
            .catch(err => {
                console.error(err);
            });

    }

    const getAssistants = (search) => {
        client.clearCache();
        indexUsersSearch.search(search, {
            facetFilters: ['rol: VOBXhlYldTnbiMNyOVFD', 'status:1']
        })
            .then(({ hits }) => {
                let assistans = [];
                hits.forEach(item => {
                    item.id = item.objectID;
                    item.text = item.name + ' ' + item.lastname;
                    assistans.push(item);
                })
                setAssistant(assistans);
            })
            .catch(err => {
                console.error(err);
            });

    }

    const generateDatesEmpty = () => {
        let dateIni = new Date();
        dateIni.setFullYear(dateIni.getFullYear() - 1);
        let dateIniStr = functions.generateDateStr(dateIni, '-', false);

        let dateEnd = new Date();
        let dateEndStr = functions.generateDateStr(dateEnd, '-', false);
        setSearchDates([dateIniStr, dateEndStr]);
        return [dateIniStr, dateEndStr];
    }

    useEffect(() => {
        getUserActive();
    }, [])

    if (!loader) {
        return (<Preloader />);
    }

    return (
        <>
            <Header />
            <Sidebar activeClassName='vital-signs' />
            <div className="page-wrapper">
                <div className="content">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Signos vitales </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right">
                                            <FeatherIcon icon="chevron-right" />
                                        </i>
                                    </li>
                                    <li className="breadcrumb-item active">Historico de signos vitales</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* /Page Header */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card card-table show-entire">
                                <div className="card-body">
                                    <div className='padding-filters-signs'>
                                        <Grid container spacing={1}>
                                            {(userLogged.ident_rol == 1) &&
                                                <Grid item xs={10} md={widthColumnMd}>
                                                    <div className="form-group local-forms">
                                                        <label className="focus-label">Auxiliar</label>
                                                        <Select
                                                            showSearch
                                                            value={searchAssistant}
                                                            placeholder="Seleccionar auxiliar"
                                                            defaultActiveFirstOption={false}
                                                            suffixIcon={null}
                                                            filterOption={false}
                                                            onSearch={handleSearchAssintant}
                                                            onChange={onChangeSelectAssintant}
                                                            notFoundContent={null}
                                                            className="form-control floating"
                                                            options={(assistants || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.text,
                                                            }))}
                                                        />
                                                    </div>
                                                </Grid>
                                            }
                                            {(userLogged.ident_rol == 2 || userLogged.ident_rol == 1) &&
                                                <Grid item xs={10} md={widthColumnMd}>
                                                    <div className="form-group local-forms">
                                                        <label className="focus-label">Residente</label>
                                                        <Select
                                                            showSearch
                                                            value={searchResident}
                                                            placeholder="Seleccionar residente"
                                                            defaultActiveFirstOption={false}
                                                            suffixIcon={null}
                                                            filterOption={false}
                                                            onSearch={handleSearchResident}
                                                            onChange={onChangeSelect}
                                                            notFoundContent={null}
                                                            className="form-control floating"
                                                            options={(residentsAssistant || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.text,
                                                            }))}
                                                        />
                                                    </div>
                                                </Grid>
                                            }
                                            {(userLogged.ident_rol == 3 && residentsUser.length > 0) &&
                                                <Grid item xs={10} md={5}>
                                                    <div className="form-group local-forms">
                                                        <label className="focus-label">Residente</label>
                                                        <Select
                                                            name="residents"
                                                            value={searchResident}
                                                            onChange={onChangeSelect}
                                                            options={residentsUser}
                                                            className="form-control floating"
                                                            style={{width: '100%'}}
                                                        />
                                                    </div>
                                                </Grid>
                                            }
                                            {(userLogged.ident_rol == 3 || userLogged.ident_rol == 1) &&
                                                <Grid item xs={10} md={widthColumnMd}>
                                                    <div className="form-group local-forms">
                                                        <label className="focus-label">Rango de fechas</label>
                                                        <RangePicker
                                                            value={[dayjs(searchDates[0], 'YYYY-MM-DD'), dayjs(searchDates[1], 'YYYY-MM-DD')]}
                                                            style={{ width: '100%', height: 45 }}
                                                            onChange={onChangeDate}
                                                        />
                                                    </div>
                                                </Grid>
                                            }
                                            <Grid item xs={1} md={1}>
                                                <div className="add-group">
                                                    <Link
                                                        to="#"
                                                        className="btn btn-primary doctor-refresh ms-2"
                                                        onClick={reloadVitalSigns}
                                                    >
                                                        <img src={refreshicon} alt="#" />
                                                    </Link>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {/* /Table Header */}
                                    <Spin spinning={!loaderData} delay={500}>
                                        <div className="table-responsive doctor-list">
                                            <Table
                                                className="table table-stripped table-hover datatable thead-light "
                                                columns={userLogged.ident_rol == 1 ? columnAdmin: column}
                                                dataSource={datasource}
                                                rowKey={(record) => record.id}
                                                pagination={{
                                                    position: ['none', 'bottomCenter'],
                                                    responsive: true,
                                                    total: totalRegisters,
                                                    pageSize: pageSize,
                                                    current: page,
                                                    onChange: changePageTable
                                                }}
                                            />
                                        </div>
                                    </Spin>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default VitalSigns;
