import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { morning_img_02 } from "../../imagepath";
import { InputMask } from '@react-input/mask';
import { Select } from 'antd';
import algoliasearch from "algoliasearch/lite";

import Header from "../../Header";
import Sidebar from "../../Sidebar";
import Preloader from "../../../components/Preloader"

import { functions } from '../../../utils/functions';
import { alertsApp } from '../../../utils/alertsApp';
import algoliaConfig from "../../../utils/Algolia";

import { residentsService } from "../../../services/resident.service";
import { vitalSignsService } from "../../../services/vital_signs.service";
import { userService } from "../../../services/user.service";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const client = algoliasearch(algoliaConfig.aplicationID, algoliaConfig.searchApiKey);
const indexSearch = client.initIndex(algoliaConfig.indexResident);

const Assistant_Dashboard = () => {
  // const [user, setUser] = useState({displayName: ''});
  const [logged, setLogged] = useState({name: '', lastname: '', name_rol: ''});
  const [textDay, setTextDay] = useState('');
  const [residents, setResidents] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isBlockSave, setIsBlockSave] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isDiabetic, setIsDiabetic] = useState(false);
  const [selectedResident, setSelectedResident] = useState('');
  const [vitalSigns, setVitalSigns] = useState({
    resident: '',
    t_a: '',
    fr_xl: '0',
    temp: '0',
    sat: '0',
    glycemia: '0',
    name_resident: '',
    representative: ''
  })
  
  const onChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    let data = { ...vitalSigns };
    if (name === 'temp' || name === 'sat' || name === 'fr_xl') {
      data[name] = functions.validNumber(value);
    } else {
      data[name] = value;
    }
    setVitalSigns(data);
  };

  const onChangeSelect = (value) => {
    console.log(value)
    setSelectedResident(value);
    let data = { ...vitalSigns };
    data.resident = value;

    residents.forEach(resident => {
      if(resident.id == value){
        setIsDiabetic(resident.diabetic);
        console.log(resident.diabetic)
      }
    })
    setVitalSigns(data);
  }

  const handleSearch = (newValue) => {
    getResidents(newValue);
  };

  const getResidents = (search) => {
    client.clearCache();
    // indexSearch.search(search)
    indexSearch.search(search, {
      filters: 'status:1'
    })
    .then(({ hits }) => {
      let residents = [];
      hits.forEach(item => {
        item.id = item.objectID;
        item.text = item.name+' '+item.lastname;
        if(!logged.test){
          if(!item.test){
            residents.push(item);
          }
        }else{
          residents.push(item);
        }
      })
      setResidents(residents);
    })
    .catch(err => {
      console.error(err);
    });
    
  }

  const clearSave = () => {
    setSelectedResident('');
    setVitalSigns({
      resident: '',
      t_a: '',
      fr_xl: '0',
      temp: '0',
      sat: '0',
      glycemia: '0',
      name_resident: '',
      representative: ''
    })
  }

  const save = async() => {
    setIsBlockSave(true);
    let resident = await residentsService.getResidentAsync(selectedResident);
    vitalSigns.name_resident = resident.name+' '+resident.lastname;
    vitalSigns.representative = resident.id_representative;
    let representative = await userService.getUserAsync(resident.id_representative);
    if(validFields()){
      insertVitalSigns(representative);
    }else{
      setIsBlockSave(false);
      alertsApp.errorAlert('Existen campos obligatorios vacios');
    }
  }


  const insertVitalSigns = (user) => {
    vitalSigns.create_date = Date.now();
    vitalSigns.update_date = Date.now();
    vitalSigns.user_create = firebase.auth().currentUser.uid;
    vitalSigns.user_update = firebase.auth().currentUser.uid;
    vitalSignsService.addRegister(vitalSigns, () => {
      if(user.token_notification){
        sendNotification(user);
      }else{
        setIsBlockSave(false);
        alertsApp.successAlert('Registrado con exito');
        clearSave();
      }
    })
  }

  const sendNotification = (user) => {
    let data = {
      token: user.token_notification,
      data: vitalSigns
    }
    // fetch('http://127.0.0.1:5001/dolcevita-abfcb/us-central1/sendNotification', {
    // fetch('https://us-central1-dolcevita-abfcb.cloudfunctions.net/activarSubscripcion', {
    fetch('https://sendnotification-qhfyekoajq-uc.a.run.app/activarSubscripcion', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
      .then(res => res.json())
      .then((result) => {
          setIsBlockSave(false);
          alertsApp.successAlert('Registrado con exito');
          clearSave();
      })
      .catch(console.error)
  }

  const validFields = () => {
    let result = true;
    if (!vitalSigns.resident || !vitalSigns.t_a || !vitalSigns.fr_xl || !vitalSigns.temp || !vitalSigns.sat) {
      result = false;
    }
    return result;
  }

  useEffect(() => {
    // setUser(firebase.auth().currentUser);
    let user = userService.getActiveUser();
    setLogged(user);
    setIsBlockSave(false);
    setResidents([]);
    const date = new Date();
    let hour = date.getHours();
    if(hour >= 5 && hour <= 12){
      setTextDay('Buenos días');
    }else{
      if(hour > 12 && hour <= 19){
        setTextDay('Buenos tardes');
      }else{
        setTextDay('Buenos noches');
      }  
    }
    if(window.screen.width <= 500){
      setIsMobile(true);
    }else{
      setIsMobile(false);
    }
    setLoader(true);
  }, [])


  if(!loader){
    return (<Preloader/>)
  }
  
  return (
    <>
      <Header />
      <Sidebar activeClassName="assistant-dashboard" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            {!isMobile && 
              <div className="page-header">
                <div className="row">
                  <div className="col-sm-12">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#">Inicio </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <i className="feather-chevron-right">
                          <FeatherIcon icon="chevron-right" />
                        </i>
                      </li>
                      <li className="breadcrumb-item active">Pantalla inicial del residente</li>
                    </ul>
                  </div>
                </div>
              </div>
            }
            {/* /Page Header */}
            <div className="good-morning-blk">
              <div className="row">
                <div className="col-md-6">
                  <div className="morning-user">
                    <h2>
                      {textDay}, <span>{logged.name}</span>
                    </h2>
                    <p>Recuerda reportar todas las novedades.</p>
                  </div>
                </div>
                {!isMobile && 
                  <div className="col-md-6 position-blk">
                    <div className="morning-img">
                      <img src={morning_img_02} alt="" />
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="doctor-list-blk">
              <div className="card-box">
                <h3 className="card-title">Signos vitales</h3>
                <Grid container spacing={1}>
                  <Grid item md xs={12}>
                    <div className="form-group local-forms">
                      <label className="focus-label">Residente <span className="login-danger">*</span></label>
                      <Select
                        showSearch
                        value={selectedResident}
                        placeholder="Seleccionar residente"
                        defaultActiveFirstOption={false}
                        suffixIcon={null}
                        filterOption={false}
                        onSearch={handleSearch}
                        onChange={onChangeSelect}
                        notFoundContent={null}
                        className="form-control floating"
                        options={(residents || []).map((d) => ({
                          value: d.id,
                          label: d.text,
                        }))}
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.5} xs={6}>
                    <div className="form-group local-forms">
                      <label className="focus-label">T/A <span className="login-danger">*</span></label>
                      <InputMask 
                        mask="___/__" 
                        replacement={{ _: /\d/ }} 
                        showMask={true}
                        name="t_a"
                        className="form-control floating"
                        value={vitalSigns.t_a}
                        onChange={onChange}
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.5} xs={6}>
                    <div className="form-group local-forms">
                      <label className="focus-label">FCx1 <span className="login-danger">*</span></label>
                      <input
                        type="text"
                        name="fr_xl"
                        className="form-control floating"
                        maxLength={6}
                        value={vitalSigns.fr_xl}
                        onChange={onChange}
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.5} xs={6}>
                    <div className="form-group local-forms">
                      <label className="focus-label">Temp. <span className="login-danger">*</span></label>
                      <input
                        type="text"
                        name="temp"
                        className="form-control floating"
                        maxLength={5}
                        value={vitalSigns.temp}
                        onChange={onChange}
                      />
                    </div>
                  </Grid>
                  <Grid item md={1.5} xs={6}>
                    <div className="form-group local-forms">
                      <label className="focus-label">Sat. <span className="login-danger">*</span></label>
                      <input
                        type="text"
                        name="sat"
                        className="form-control floating"
                        maxLength={6}
                        value={vitalSigns.sat}
                        onChange={onChange}
                      />
                    </div>
                  </Grid>
                  {isDiabetic && 
                    <Grid item md={1.5} xs={6}>
                      <div className="form-group local-forms">
                        <label className="focus-label">Glucemia <span className="login-danger">*</span></label>
                        <input
                          type="text"
                          name="glycemia"
                          className="form-control floating"
                          maxLength={6}
                          value={vitalSigns.glycemia}
                          onChange={onChange}
                        />
                      </div>
                    </Grid>
                  }
                  <Grid item md={2} xs={12}>
                    <div className="doctor-submit">
                      <button
                        type="button"
                        className="btn btn-primary submit-list-form me-2"
                        onClick={save}
                        disabled={isBlockSave}
                      >
                        {isBlockSave &&
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        }
                        {isBlockSave ? ' Procesando...' : 'Registrar'}
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Assistant_Dashboard;
