import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import BaseService from './base.service';
import { rolesService } from './roles.service';

class UserService extends BaseService{

    user = null;
    activeUser = null;

    constructor(){
        super();
        this.collection = 'users';
    }

    getUser(userId){
        return firebase.firestore()
        .collection(this.collection)
        .doc(userId)
        .get();
    }

    getUserByDocument(document){
        return firebase.firestore()
        .collection(this.collection)
        .where('document', '==', document)
        .get();
    }

    getUserByEmail(email){
        return firebase.firestore()
        .collection(this.collection)
        .where('email', '==', email)
        .get();
    }

    async getUserAsync(id){
        let rol = await firebase.firestore()
        .collection(this.collection)
        .doc(id).get();
        return rol.data();
    }

    async getUserActive(){
        let user = await firebase.firestore()
        .collection(this.collection)
        .doc(firebase.auth().currentUser.uid)
        .get();

        let result = Object.assign({}, user.data());
        result.id = user.id;
        let rol = await rolesService.getRolAsync(result.rol);
        result.name_rol = rol.rol;
        return result;
    }

    getDataUser(){
        return this.user;
    }

    setDataUser(user){
        this.user = user;
    }

    getActiveUser(){
        return this.activeUser;
    }

    setActiveUser(activeUser){
        this.activeUser = activeUser;
    }
}

export const userService = new UserService();
