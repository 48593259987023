/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";


import { morning_img_03 } from "../../imagepath";

import Preloader from '../../../components/Preloader';
import { alertsApp } from "../../../utils/alertsApp";
import VitalSigns from "./VitalSigns";

import { residentsService } from "../../../services/resident.service";
import { userService } from "../../../services/user.service";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getToken, onMessage, getMessaging } from 'firebase/messaging';
import firebaseConfig from "../../../utils/Firebase";

const firebaseApp = firebase.initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const Patient_Dashboard = () => {
  const [user, setUser] = useState({ displayName: '' });
  const [textDay, setTextDay] = useState('');
  const [loader, setLoader] = useState(false);
  const [residents, setResidents] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [withNotification, setWithNotification] = useState(false);

  const getResidents = () => {
    residentsService.getResidentsUser(firebase.auth().currentUser.uid).onSnapshot({
      next: async (snapshot) => {
        let residents = [];
        snapshot.forEach((item) => {
          let resident = item.data();
          resident.id = item.id;
          residents.push(resident);
        })
        setResidents(residents);
        activeNotification();
        
      }
    })
  }

  const activeNotification = () => {
    Notification.requestPermission().then(permission => {
      setLoader(true);
      if(permission === 'denied'){
        alertsApp.errorAlert('No se concedió el permiso de notificaciones');
        setWithNotification(false);
      }else{
        if(permission === 'default'){
          alertsApp.errorAlert('La solicitud de permiso fue desestimada.');
          setWithNotification(false);
        }else{
          setWithNotification(true);
          validToken();
          
        }
      }
    })
  }

  const validToken = () => {
    getToken(messaging, {
      vapidKey: "BN_zxWFWSFZSkO6DfbS28tQegfVo2jRTiNLRZyyU1pilnao6kgd5CKEC2GMlawtL3RXi-rYe17p_KOXbi20niqc"
    }).then(res => {
      let data = {token_notification: res}
      userService.updateRegister(firebase.auth().currentUser.uid, data, () => {
        if(withNotification){
          listenMessage();
        }
      })
    }).catch(error => console.error('Error al generar el token', error))
  }

  const listenMessage = () => {
    onMessage(messaging, {
      next: message => {
        alertsApp.successAlert(message.notification.title);
      },
      error: err => {
        console.error(err);
      }
    })
  }

  useEffect(() => {
    setLoader(false);
    setUser(firebase.auth().currentUser);
    const date = new Date();
    let hour = date.getHours();
    if (hour >= 5 && hour <= 12) {
      setTextDay('Buenos días');
    } else {
      if (hour > 12 && hour <= 19) {
        setTextDay('Buenos tardes');
      } else {
        setTextDay('Buenos noches');
      }
    }
    if(window.screen.width <= 500){
      setIsMobile(true);
    }else{
      setIsMobile(false);
    }
    getResidents();
  }, [])


  if (!loader) {
    return (<Preloader />)
  }

  return (
    <>
      <Header />
      <Sidebar activeClassName="patient-dashboard" />
      {/* <Sidebar id="menu-item"
        id1="menu-items"
        activeClassName="patient-dashboard" /> */}
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            {!isMobile && 
              <div className="page-header">
                <div className="row">
                  <div className="col-sm-12">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="#">Inicio </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <i className="feather-chevron-right">
                          <FeatherIcon icon="chevron-right" />
                        </i>
                      </li>
                      <li className="breadcrumb-item active">
                        Pantalla principal
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            }
            {/* /Page Header */}
            <div className="good-morning-blk">
              <div className="row">
                <div className="col-md-6">
                  <div className="morning-user">
                    <h2>
                      {textDay}, <span>{user.displayName}</span>
                    </h2>
                    {/* <p>A continuación, condición de salud de Cecilia.</p> */}
                  </div>
                </div>
                {!isMobile && 
                  <div className="col-md-6 position-blk">
                    <div className="morning-img">
                      <img src={morning_img_03} alt="#" />
                    </div>
                  </div>
                }
              </div>
            </div>
            {residents.map((resident, indexR) => (
              <div key={`resid-${indexR}`}>
                <VitalSigns resident={resident}/>
              </div>
            ))}
          </div>
        </div>
      </>
    </>
  );
};

export default Patient_Dashboard;
