import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const Signout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        firebase.auth().signOut()
        .then(() => {
            navigate('/');
            // window.location.reload();
        })
        .catch((error) => {
            console.error(error);
            navigate('/');
            // window.location.reload();
        })    
    }, [])

    return (
        <div>Salir</div>
    )
}

export default Signout;