/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Table } from "antd";
import Header from '../Header';
import Sidebar from '../Sidebar';
import algoliasearch from "algoliasearch/lite";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { onShowSizeChange, itemRender } from '../Pagination'
import {
    imagesend,
    imguser,
    refreshicon,
    searchnormal,
    plusicon
} from "../imagepath";
import { Link } from 'react-router-dom';

import algoliaConfig from "../../utils/Algolia";
import { alertsApp } from '../../utils/alertsApp';

import { userService } from '../../services/user.service';
import { rolesService } from '../../services/roles.service';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const client = algoliasearch(algoliaConfig.aplicationID, algoliaConfig.searchApiKey);
const indexSearch = client.initIndex(algoliaConfig.indexUser);

const AssistantsList = () => {
    const [loader, setLoader] = useState(false);
    const [logged, setLogged] = useState({name: '', lastname: '', name_rol: ''});
    const [datasource, setDatasource] = useState([]);
    const [rolAssistant, setRolAssistant] = useState('');
    const [userSelect, setUserSelect] = useState('');
    const [search, setSearch] = useState('');
    const [totalRegisters, setTotalRegisters] = useState(0);
    const [page, setPage] = useState(1);
    const pageSize = 10;

    const column = [
        {
            title: "N° documento",
            dataIndex: "document",
            key: "document",
            render: (text, record) => (
                <>
                    <h2 className="profile-image">
                        <Link to="/profile" className="avatar avatar-sm me-2">
                            <img
                                className="avatar-img rounded-circle"
                                src={record.img ? record.img : imguser}
                                alt="User Image"
                            />
                        </Link>
                        <Link to="/profile">{record.document}</Link>
                    </h2>
                </>
            ),
        },
        {
            title: "Nombre",
            dataIndex: "name",
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "Apellido",
            dataIndex: "lastname",
            sorter: (a, b) => a.lastname.length - b.lastname.length,
        },
        {
            title: "N° celular",
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "",
            dataIndex: "FIELD8",
            render: (text, record) => (
                <>
                    <div className="text-end">
                        <div className="dropdown dropdown-action">
                            <Link
                                to="#"
                                className="action-icon dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <i className="fas fa-ellipsis-v" />
                            </Link>
                            <div className="dropdown-menu dropdown-menu-end">
                                <Link className="dropdown-item" to="/edit-assistant" onClick={selectRow(record)}>
                                    <i className="far fa-edit me-2" /> Editar
                                </Link>
                                <Link className="dropdown-item" to="#" data-bs-toggle="modal"
                                    data-bs-target="#delete_patient"
                                    onClick={selectRow(record)}
                                >
                                    <i className="fa fa-trash-alt m-r-5"></i> Inactivar
                                </Link>
                            </div>
                        </div>
                    </div>
                </>
            ),
        },
    ];

    const onChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'search') {
            setSearch(value);
            getUsers(0, value, rolAssistant.id, logged);
        }
    };

    const selectRow = (row) => () => {
        setUserSelect(row);
        userService.setDataUser(row);
    }

    const inactiveUser = () => {
        let data = { status: '0' }
        userService.updateRegister(userSelect.id, data, () => {
            alertsApp.successAlert('Auxiliar inactivado con exito');
            getUsers(page, search, rolAssistant.id, logged);
        })
    }

    // const inactiveAuthentication = () => {
    //     let data = {
    //         uid: userSelect.id,
    //     }
    //     fetch('http://127.0.0.1:5001/dolcevita-abfcb/us-central1/inactiveAuthentication', {
    //         //   fetch('https://us-central1-tienda-54320.cloudfunctions.net/activarSubscripcion', {
    //         method: 'POST',
    //         body: JSON.stringify(data),
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Accept': 'application/json',
    //         },
    //     })
    //         .then(res => res.json())
    //         .then((result) => {
    //         })
    //         .catch(console.error)
    // }

    const getAssistantRol = (user) => {
        rolesService.getRolByIdent('2').then(snapshot => {
            let rol = ''
            snapshot.forEach(doc => {
                rol = {
                    id: doc.id,
                    ident_rol: doc.data().ident_rol
                };
            })
            setRolAssistant(rol);
            getUsers(0, '', rol.id, user);
        })
    }

    const getUsers = (page, search, rol, user) => {
        setDatasource([]);
        client.clearCache();
        indexSearch.search(search, {
            page: page,
            hitsPerPage: pageSize,
            facetFilters: ['rol: ' + rol, 'status:1']
        })
            .then((result) => {
                let assistans = [];
                let num_assistant = 0;
                if(!user.test){
                    result.hits.forEach(hit => {
                        if(!hit.test){
                            assistans.push(hit);
                        }
                    })
                    num_assistant = assistans.length;
                }else{
                    assistans = result.hits;
                    num_assistant = result.nbHits;
                }
                setTotalRegisters(num_assistant);
                generateUser(assistans);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const generateUser = (registers) => {
        let users = [];
        registers.forEach(doc => {
            let item = doc
            item.id = doc.objectID
            users.push(item);
        })
        setDatasource(users);
    }

    const reloadUsers = () => {
        setSearch('');
        getUsers(0, '', rolAssistant.id, logged);
    }

    const changePageTable = (value) => {
        setPage(value);
        let page = value - 1;
        getUsers(page, search, rolAssistant.id, logged);
    }

    useEffect(() => {
        userService.setDataUser(null);
        let user = userService.getActiveUser();
        setLogged(user);
        setSearch('');
        getAssistantRol(user);
    }, [])

    return (
        <>
            <Header />
            <Sidebar id='menu-item1' id1='menu-items1' activeClassName='assistants-list' />
            <div className="page-wrapper">
                <div className="content">
                    {/* Page Header */}
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Auxiliares </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <i className="feather-chevron-right">
                                            <FeatherIcon icon="chevron-right" />
                                        </i>
                                    </li>
                                    <li className="breadcrumb-item active">Listado de auxiliares</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* /Page Header */}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card card-table show-entire">
                                <div className="card-body">
                                    {/* Table Header */}
                                    <div className="page-table-header mb-2">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <div className="doctor-table-blk">
                                                    <h3>Listado de residentes</h3>
                                                    <div className="doctor-search-blk">
                                                        <div className="top-nav-search table-search-blk">
                                                            <form>
                                                                <input
                                                                    type="text"
                                                                    name='search'
                                                                    value={search}
                                                                    className="form-control"
                                                                    placeholder="Search here"
                                                                    onChange={onChange}
                                                                />
                                                                <Link className="btn">
                                                                    <img
                                                                        src={searchnormal}
                                                                        alt="#"
                                                                    />
                                                                </Link>
                                                            </form>
                                                        </div>
                                                        <div className="add-group">
                                                            <Link
                                                                to="/add-assistant"
                                                                className="btn btn-primary add-pluss ms-2"
                                                            >

                                                                <img src={plusicon} alt="#" />
                                                            </Link>
                                                            <Link
                                                                to="#"
                                                                className="btn btn-primary doctor-refresh ms-2"
                                                                onClick={reloadUsers}
                                                            >
                                                                <img src={refreshicon} alt="#" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-auto text-end float-end ms-auto download-grp">
                    <Link to="#" className=" me-2">
                      <img src={pdficon} alt="#" />
                    </Link>
                    <Link to="#" className=" me-2">
                    </Link>
                    <Link to="#" className=" me-2">
                      <img src={pdficon3} alt="#" />
                    </Link>
                    <Link to="#">
                      <img src={pdficon4} alt="#" />
                    </Link>
                  </div> */}
                                        </div>
                                    </div>
                                    {/* /Table Header */}
                                    <div className="table-responsive doctor-list">
                                        <Table
                                            className="table table-stripped table-hover datatable thead-light "
                                            columns={column}
                                            dataSource={datasource}
                                            rowKey={(record) => record.id}
                                            pagination={{
                                                total: totalRegisters,
                                                pageSize: pageSize,
                                                current: page,
                                                onChange: changePageTable
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="delete_patient" className="modal fade delete-modal" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body text-center">
                            <img src={imagesend} alt="#" width={50} height={46} />
                            <h3>Esta seguro de eliminar el registro ?</h3>
                            <div className="m-t-20">
                                {" "}
                                <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
                                    Cancelar
                                </Link>
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={inactiveUser}>
                                    Inactivar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default AssistantsList;
